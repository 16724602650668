import React, { Fragment } from "react";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import Footer from "../components/footer";
import "./contact.css";

const Contact = (props) => {
  return (
    <div className="contact-container">
      <Helmet>
        <title>Contact - Feelers 2025</title>
        <meta property="og:title" content="Contact - Feelers 2025" />
      </Helmet>
      <div className="contact-bg-container">
        <img src="/external/bgcircle.png" className="contact-image1" />
        <img src="/external/bgcircle.png" className="contact-image2" />
      </div>
      <div className="contact-navbar-container">
        <Navbar navImgSrc="/feelers/feelerslogo.png"></Navbar>
      </div>
      <div className="contact-body-container">
        <span className="contact-text10 Body-XL">
          We&apos;re open to conversations and collaborations within and beyond
          the realms of art, science, business and technology.
          <span
            dangerouslySetInnerHTML={{
              __html: " ",
            }}
          />
        </span>
        <span className="contact-text11 Alt1-M">
          <span>team@feelers-feelers.com</span>
          <br></br>
          <span>284 River Valley Road,</span>
          <br></br>
          <span>Singapore 238325</span>
          <br></br>
        </span>
      </div>
      <div className="contact-footer-container">
        <Footer
          link={
            <Fragment>
              <span className="contact-text18">
                © 2025 by Feelers. All Rights Reserved. Feelers is a project
                housed within Potato Productions.
              </span>
            </Fragment>
          }
          link1={
            <Fragment>
              <span className="contact-text19">
                More information about our Privacy Policy.
              </span>
            </Fragment>
          }
          rootClassName="footerroot-class-name"
        ></Footer>
      </div>
    </div>
  );
};

export default Contact;
