import React, { Fragment } from "react";

import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import NavbarB from "../components/navbar-b";
import Footer from "../components/footer";
import "./reflective-essay.css";

const ReflectiveEssay = (props) => {
  return (
    <div className="reflective-essay-container">
      <Helmet>
        <title>Introduction - Feelers 2025</title>
        <meta property="og:title" content="Introduction - Feelers 2025" />
      </Helmet>
      <div className="reflective-essay-navbar-container">
        <NavbarB rootClassName="navbar-broot-class-name2"></NavbarB>
      </div>
      <div className="reflective-essay-ressay">
        <div className="para-section-col">
          <div className="reflective-essay-ressay-r1l1">
            <img
              alt="image"
              src="/oddsandends/oe-07-metalangle.png"
              loading="eager"
              className="reflective-essay-image1"
            />
          </div>
          <div className="reflective-essay-ressay-r1r2">
            <span className="title-text OE-Headline">introduction</span>
            <span className="red-sub-text OE-SubheadingF25">
              How do we handle what remains?
            </span>
            <span className="blue-sub-text OE-BodyF25">by ants chua</span>
            <div className="para1-4-container">
              <span className="para-num OE-Numerals">1</span>
              <span className="para-text OE-BodyAN">
                I’m a compulsive link-clicker. My burdened computer is always
                trying its best to keep countless tabs open while downloading a
                cascade of files that arrive faster than they can be organised.
                <br></br>
                <br></br>
                When I get to too many windows, my phone browser offers me the
                option to close all tabs that have been open for longer than a
                month. I never click yes. Some of them have been open for years,
                fragments of interest and intention that have travelled with me
                through time.
                <br></br>
                <br></br>
                “They’re all very interesting,” I think to myself, skimming the
                webpages I haven’t glanced at since the last time I thought of
                closing them. Perhaps I’ll come back to them one day; perhaps if
                I was once the person who opened a window then one day I’ll be
                the person whose interest in it rekindles.
              </span>
            </div>
            <div className="para1-4-container">
              <span className="para-num OE-Numerals">2</span>
              <span className="para-text OE-BodyAN">
                So much of the uncategorisable clutter I accumulate at home
                comes with a similar set of wishes about a self that might
                re-emerge. This person might once again find uses for the ragtag
                gathering of items in a drawer in my kitchen: an onigiri mould,
                glass jars for pickling vegetables, two half-used bags of
                tapioca starch, a mostly-full box of instant yeast, and a tiny
                cheese grater whose handle is just barely hanging on.
              </span>
            </div>
            <div className="para1-4-container">
              <span className="para-num OE-Numerals">3</span>
              <span className="para-text OE-BodyAN">
                When a space, digital or physical, must be said farewell to, we
                bring what we can to its gleaming replacement. We learn to cook
                on unfamiliar stoves, create new folders in empty hard drives.
                We bring our files, apps, protocols, pots, and pans along.
                <br></br>
                <br></br>
                It takes me the longest time to parse the miscellaneous
                assortments that accompany us through life, splinters of
                attention that wandered off-road in search of something one
                cannot quite name. These move from place to place and are seldom
                called on, if ever, but I can’t bring myself to toss them out.
              </span>
            </div>
            <div className="para1-4-container">
              <span className="para-num OE-Numerals">4</span>
              <span className="para-text OE-BodyAN">
                The tech industry often pulls us down pathways that it makes
                seem inevitable. It assures us of solutions to problems we
                haven’t even fully understood, hurtling toward conclusions it
                takes as foregone.
                <br></br>
                <br></br>A platform claims to connect us, an app will cut our
                working hours short.
                <br></br>
                An algorithm says it will see us more clearly than we see
                ourselves.
                <br></br>
                Technology promises the end of work, of forgetting, of
                loneliness.
                <br></br>
                <br></br>
                It tells us which questions to ask and answer, a loop that never
                opens itself up.
              </span>
            </div>
          </div>
        </div>
        <div className="para-section">
          <div className="reflective-essay-ressay-r2r2">
            <div className="reflective-essay-ressay-r2r11">
              <span className="para-num OE-Numerals">5</span>
              <span className="para-text OE-BodyF25">
                Odds and Ends is an invitation: to linger, to meander, to stay
                still amidst the drumming rhythm of forward motion and treasure
                how things scatter against the hard edge of a finish line.
                <br></br>
                <br></br>
                <span className="para-text-red">
                  This season, we invite the story to stutter, perhaps even
                  dissolve mid-paragraph.
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="para-section">
          <div className="reflective-essay-ressay-r3l">
            <img
              alt="image"
              src="/oddsandends/oe-09-bar.png"
              loading="eager"
              className="reflective-essay-image2"
            />
          </div>
          <div className="reflective-essay-ressay-r3r">
            <div className="reflective-essay-ressay-r1r15">
              <span className="reflective-essay-text154 OE-BodyAN">
                <span className="para-text OE-BodyAN">With </span>

                <a
                  href="https://forms.gle/Q9R3TDn3NVktm5g76"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="para-text-blue-link OE-BodyF25"
                >
                  <span>Open Endings</span>
                </a>
                <span className="para-text OE-BodyAN">
                  , a reading circle and speculative writing workshop, we reach
                  past the conclusion. We turn to that which we don’t have a
                  narrative for yet.
                </span>
                <br></br>
                <br></br>
                <span className="para-text-blue OE-BodyF25">
                  Contextual Thread
                </span>
                <span className="para-text OE-BodyAN">
                  , an experiment in web- and browser- poetics by Bao Anh Bui,
                  features writing by Izyanti Asa’ari, Samuel Caleb Wee, and
                  Andrew Devadason, celebrating trivial pursuits and digital
                  detours.
                </span>
                <br></br>
                <br></br>
                <Link
                  to="/to-dwell-a-little-longer"
                  className="para-text-blue-link OE-BodyF25"
                >
                  <span>To Dwell a Little Longer</span>
                </Link>
                <span className="para-text OE-BodyAN">
                  , an editorial by Teo Xiao Ting, meditates on how technical
                  practices might ground and grow in surprising places before
                  they disperse and drift anew.
                </span>
                <br></br>
                <br></br>
                <span className="para-text-blue OE-BodyF25">
                  Delete Parties
                </span>
                <span className="para-text OE-BodyAN">
                  {" "}
                  are drop-in cosy spaces where we can snack, chat, and decide
                  what to save from the flotsam and jetsam of our digital lives.
                </span>
                <br></br>
                <br></br>
                <a
                  href="https://forms.gle/De1musyc1cLUZ9ibA"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="para-text-blue-link OE-BodyF25"
                >
                  <span>Circuits and Transits</span>
                </a>
                <span className="para-text OE-BodyAN">
                  {" "}
                  is a participatory ritual of disassembly to create talismans
                  for wayfinding.
                </span>
                <br></br>
                <br></br>
                <span className="para-text OE-BodyAN">
                  We close with a mainstay of our seasons:{" "}
                </span>
                <a
                  href="https://forms.gle/ESKPFdRZg57QYCdm8"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="para-text-blue-link OE-BodyF25"
                >
                  <span>How To Go On</span>
                </a>
                <span className="para-text OE-BodyAN">
                  , an intimate discussion between young arts professionals
                  about how we begin, end, and begin again.
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="para-section">
          <div className="reflective-essay-ressay-r2r3">
            <div className="reflective-essay-ressay-r2r12">
              <span className="para-num OE-Numerals">6</span>
              <span className="para-text OE-BodyF25">
                Can we give each other the difficult gift of uncertainty?
                <br></br>
                <br></br>I wish us patience to linger with partial, provisional
                knowledge, strength to stay on our knees.
                <br></br>
                <br></br>I wish us fragmented ways of being and the freedoms
                that might come with them.
                <br></br>
                <br></br>I wish us the curiosity to explore shapes that we
                cannot yet see, worlds that we do not, and may never, fully
                understand.
              </span>
            </div>
          </div>
        </div>
        <div className="para-section">
          <div className="reflective-essay-ressay-r1l2">
            <img
              alt="image"
              src="/oddsandends/oe-11-wires.png"
              loading="eager"
              className="reflective-essay-image3"
            />
          </div>
          <div className="reflective-essay-ressay-r1r3">
            <div className="reflective-essay-ressay-r1r16">
              <span className="para-num OE-Numerals">7</span>
              <span className="para-text OE-BodyAN">
                The rain fell thick and heavy last night. The day after the
                deluge, plant trimmings were scattered about the void deck,
                sheared off from their parent plants by the force of the wind.
                <br></br>
                <br></br>I picked up a sprig of mint and brought it home. In
                that peculiar drawer, I found an old bottle whose cap had long
                since disappeared and placed the mint in some fresh water.
                <br></br>
                <br></br>
                I’m waiting for it to root.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="reflective-essay-footer-container">
        <Footer
          link={
            <Fragment>
              <span className="reflective-essay-text206">
                © 2025 by Feelers. All Rights Reserved. Feelers is a project
                housed within Potato Productions.
              </span>
            </Fragment>
          }
          link1={
            <Fragment>
              <span className="reflective-essay-text207">
                More information about our Privacy Policy.
              </span>
            </Fragment>
          }
          rootClassName="footerroot-class-name30"
        ></Footer>
      </div>
    </div>
  );
};

export default ReflectiveEssay;
