import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './footer.css'

const Footer = (props) => {
  return (
    <div className={`footer-footer ${props.rootClassName} `}>
      <div className="footer-copyright-container">
        <span className="footer-link1 Alt1-XS">
          {props.link ?? (
            <Fragment>
              <span className="footer-text2">
                © 2025 by Feelers. All Rights Reserved. Feelers is a project
                housed within Potato Productions.
              </span>
            </Fragment>
          )}
        </span>
        <Link to="/privacy-policy" className="footer-link2 Alt1-XS">
          {props.link1 ?? (
            <Fragment>
              <span className="footer-text1">
                More information about our Privacy Policy.
              </span>
            </Fragment>
          )}
        </Link>
      </div>
    </div>
  )
}

Footer.defaultProps = {
  rootClassName1: '',
  link1: undefined,
  link: undefined,
  rootClassName: '',
}

Footer.propTypes = {
  rootClassName1: PropTypes.string,
  link1: PropTypes.element,
  link: PropTypes.element,
  rootClassName: PropTypes.string,
}

export default Footer
