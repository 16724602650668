import React, { Fragment } from "react";

import { Helmet } from "react-helmet";

import NavbarB from "../components/navbar-b";
import Footer from "../components/footer";
import "./to-dwell-a-little-longer.css";

const ToDwellALittleLonger = (props) => {
  return (
    <div className="to-dwell-a-little-longer-container">
      <Helmet>
        <title>To Dwell A Little Longer - Feelers 2025</title>
        <meta
          property="og:title"
          content="To Dwell A Little Longer - Feelers 2025"
        />
      </Helmet>
      <div className="to-dwell-a-little-longer-editorial-bg-container">
        <div className="to-dwell-a-little-longer-editorial-bg-l">
          <img
            alt="image"
            src="/oddsandends/oe-08-pillar.png"
            loading="eager"
            className="to-dwell-a-little-longer-image10"
          />
          <img
            alt="image"
            src="/oddsandends/oe-08-pillar.png"
            loading="eager"
            className="to-dwell-a-little-longer-image11"
          />
          <img
            alt="image"
            src="/oddsandends/oe-08-pillar.png"
            loading="eager"
            className="to-dwell-a-little-longer-image12"
          />
          <img
            alt="image"
            src="/oddsandends/oe-08-pillar.png"
            loading="eager"
            className="to-dwell-a-little-longer-image13"
          />
        </div>
      </div>
      <div className="to-dwell-a-little-longer-navbar-container">
        <NavbarB rootClassName="navbar-broot-class-name3"></NavbarB>
      </div>
      <div className="to-dwell-a-little-longer-editorial-r1">
        <div className="to-dwell-a-little-longer-editorial-body">
          <span className="main-title OE-Headline">
            To Dwell
            <br></br>A Little Longer
          </span>
          <span className="blue-text OE-BodyF25">by Teo Xiao Ting</span>
          <img
            alt="image"
            src="/oddsandends/oe-09-bar.png"
            className="to-dwell-a-little-longer-image14"
          />
          <span className="quote-text OE-SubheadingF25">
            “…when hope becomes a practice, we do not know what we hope for, and
            our hope does not take on a propositional content. Hope is rather
            the way in which we uphold or reinterpret the world so that sense
            can come.
            <br></br>
            <br></br>
            We engage in and take up situations we do not master. We engage in
            doings and praxes that might not have any meaning in the moment, but
            are constitutive of meaning to come.”
          </span>
          <span className="quote-credit-text OE-BodyF25">
            —Line Ryberg Ingerslev,
          </span>
          <a
            href="https://ojs.uclouvain.be/index.php/theologica/article/view/67743/70143"
            target="_blank"
            rel="noreferrer noopener"
            className="quote-credit-link OE-BodyF25"
          >
            What the Experience of Transience Tells Us About the Afterlife
          </a>
          <img
            alt="image"
            src="/oddsandends/oe-09-bar.png"
            className="to-dwell-a-little-longer-image15"
          />
          <div className="to-dwell-a-little-longer-editotial-r1">
            <div className="to-dwell-a-little-longer-editorial-r1l">
              <img
                alt="image"
                src="/oddsandends/oe-tdall_01.webp"
                loading="eager"
                className="to-dwell-a-little-longer-image16"
              />
              <span className="to-dwell-a-little-longer-text114 OE-FootnoteF25">
                ‘No EXIT’ sign at the back of Continental Electronics, at Sim
                Lim Tower
              </span>
            </div>
            <div className="to-dwell-a-little-longer-editorial-r1r">
              <span className="body-para-text OE-BodyAN">
                If we look closely, there are endings everywhere, ranging from
                monumental to minute. We experience them all the time—the
                strange disorientation when you realise your favourite fish soup
                hawkers have moved on, replaced by yet another western food
                stall; a software update suddenly flashes incompatible with a
                phone that’s been with you for years; grey noise barriers cloak
                a building you’re used to seeing before it all eventually
                disappears. 
                <br></br>
                <br></br>
                If we dwell a little longer, endings also conjure a sense of
                completion, a process of sense-making amidst the scrambling of
                data points previously thought invariable, a clearing. A
                throughline: disorientation. So much of our lives risks being
                taken for granted, assumed to remain the same until it changes.
                Until change arrives, though, we cannot predict and plot the
                future, no matter how many data points we have about the past.
                What lies beyond the ending also lies just beyond our
                imagination. It is a necessarily disorientating practice to
                reach just-beyond, to not hurry towards certainty before it is
                ripe.
                <br></br>
                <br></br>
                How then do we move, without the certainty of knowing? I think
                of grief and mourning not just as a process after loss, but also
                an act of worldbuilding after irreparable change.
                <span className="red-text OE-FootnoteF25">1</span>
                 Grief and its associated affect don’t need material loss to be
                legitimised; the loss of an orientation or ideal can birth
                grief, too. The generative process of worldbuilding might not
                feel as hopeful as it sounds when we are in the thick of (future
                or past) loss, but here is where I remember—making different
                sense than what we are used to will inevitably feel uncannily
                uncomfortable, even senseless.
              </span>
              <div className="to-dwell-a-little-longer-linksplitter">
                <span>
                  <span className="red-text OE-FootnoteF25">1</span>
                  <span className="OE-FootnoteAN">
                    {" "}
                    Sparked off by Jessica Dore’s Substack newsletter, 
                  </span>
                </span>
                <a
                  href="https://jessicadore.substack.com/p/offering-december-17-2023"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="to-dwell-a-little-longer-link2 OE-FootnoteAN"
                >
                  Fear of intimacy, fear of mourning
                </a>
              </div>
            </div>
          </div>
          <div className="to-dwell-a-little-longer-editorial-r2">
            <img
              alt="image"
              src="/oddsandends/oe-tdall_02.webp"
              loading="eager"
              className="to-dwell-a-little-longer-image17"
            />
            <span className="to-dwell-a-little-longer-text131 OE-FootnoteF25">
              Storefront at an unmanned store in Sim Lim Tower
            </span>
          </div>
          <div className="to-dwell-a-little-longer-editorial-r3">
            <div className="to-dwell-a-little-longer-editorial-r3l1">
              <img
                alt="image"
                src="/oddsandends/oe-12-cnt.png"
                loading="eager"
                className="to-dwell-a-little-longer-image18"
              />
            </div>
            <div className="to-dwell-a-little-longer-editorial-r3r1">
              <span className="body-para-text OE-BodyAN">
                In the labyrinth of Sim Lim Tower, there are stores with, among
                other things, a seemingly endless supply of buttons. Each with a
                different texture, size, and tactility, huddled together in
                small plastic trays. In other aisles, transistors, resistors,
                and a variety of other parts. Across the road, is Sim Lim
                Square. I should note here that Sim Lim is the transliteration
                of 森林 (sēn lín), which translates to “forest”. 
                <br></br>
                <br></br>
                Between the twin forests, if you want new things that are ready
                for immediate use, go to Sim Lim Square; if you want spare parts
                to repair or build something entirely of your own, go to Sim Lim
                Tower. It is seldom the bustling complex it used to be, but the
                stores are still here with their seemingly endless supply of
                spare parts. If you are looking for an emergency purchase, here
                is where you need not wait for pixels to translate into
                material. Ironically, for all the instantaneous gratification
                that online shopping promises, it hasn’t transcended the
                logistical heft of shipping. 
                <br></br>
                <br></br>
                When I first stepped into Continental Electronics, I had time to
                spare. It has been around for more than thirty years, longer
                than I have been alive. Yet it was my first time there, and the
                objects stocked in abundance rendered me clueless. I picked up a
                small black creature with metal legs, and learnt later from
                Auntie May that it is a transistor: a unit of electronics so
                basic and everyday that she couldn&apos;t explain what it was
                for. Even now, I am typing on a device that is built with
                transistors, resistors, chips, parts that are incomprehensible
                to me. Though they need to join a larger whole to be functional,
                in this shop these parts feel self-sufficient.
              </span>
            </div>
          </div>
          <div className="to-dwell-a-little-longer-editorial-r4">
            <div className="to-dwell-a-little-longer-editorial-r4l">
              <img
                alt="image"
                src="/oddsandends/oe-tdall_03.webp"
                loading="eager"
                className="to-dwell-a-little-longer-image19"
              />
              <span className="to-dwell-a-little-longer-text143 OE-FootnoteF25">
                Buttons in a plastic tray at Continental Electronics
              </span>
            </div>
            <div className="to-dwell-a-little-longer-editorial-r4r">
              <span className="body-para-text OE-BodyAN">
                Feelers’ Team Lead, Ashley, introduced Continental Electronics
                to me. Ashley told me she had been frequenting this store since
                she first began her artistic practice as a student. As I
                wandered around the space, I started chatting with the aunties
                working there, including Auntie May. She has been working at the
                store since she was 16 years old, and as I zipped through the
                aisles, she smiled and told me when she first came into contact
                with electronics, she was confused too. After more than a decade
                of working there, she learnt the language and functionalities of
                these parts. Not because she picked it up from school, but she
                came to know them through sheer exposure and her own curiosity. 
                <br></br>
                <br></br>
                Each member of the Feelers team has their own relationship with
                Sim Lim Tower—Ashley’s practice involves using kinetic
                sculptures to create sensory spaces and generative experiences,
                which has been developing since she was an art student; while
                ada, Feelers’ Tech Associate, has been visiting Sim Lim Tower
                since their teenage years and now has a repair practice that
                often require spare parts. I wandered and grazed amidst the
                buttons with ada. My hands instinctively started to comb through
                the various buttons, pressing away. “For buttons, I need to feel
                it. Seeing it in an online catalogue is not enough,” ada mused.
                Even if it’s the exact same model, it might not feel the same.
                When I asked them how it is like frequenting the stores, they
                described it as a playground with nooks and crannies that might
                just yield parts and chips that are out of production, and can
                no longer be found online.
                <br></br>
                <br></br>
                What happens when the physical stores phase out? What if that is
                an inevitability? I didn’t ask that question then, but I think
                we would carry on with the memory of how it feels to peruse
                button after button under fluorescent lights, seeking the click
                that feels exactly right.
              </span>
            </div>
          </div>
          <span className="to-dwell-a-little-longer-text155 OE-SubheadingF25">
            We would find other ways of sensing and seeking the things we need,
            that we might not know we need until we encounter them. 
          </span>
          <img
            alt="image"
            src="/oddsandends/oe-08-pillar.png"
            className="to-dwell-a-little-longer-image20"
          />
          <div className="to-dwell-a-little-longer-editorial-r5">
            <div className="to-dwell-a-little-longer-editorial-r5l">
              <img
                alt="image"
                src="/oddsandends/oe-11-wires.png"
                loading="eager"
                className="to-dwell-a-little-longer-image21"
              />
              <img
                alt="image"
                src="/oddsandends/oe-tdall_04.webp"
                loading="eager"
                className="to-dwell-a-little-longer-image22"
              />
              <span className="to-dwell-a-little-longer-text158 OE-FootnoteF25">
                Parts on display on the second level of Sim Lim Tower 
              </span>
            </div>
            <div className="to-dwell-a-little-longer-editorial-r5r">
              <span className="body-para-text OE-BodyAN">
                It’s difficult to resist preemptive grief when it comes to
                endings in Singapore. We see it all the time—buildings
                demolished before the turn of a generation, playback devices
                becoming obsolete, electronics being discarded at a rate of{" "}
                <a
                  href="https://www.straitstimes.com/world/e-waste-growing-five-times-faster-than-official-recycling-rates-un"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="body-text-link OE-BodyAN"
                >
                  60,000 tonnes a year
                </a>
                . Yet at the same time, beginnings are interspersed within the
                matrix of endings. Buildings spring up, restoration and
                preservation projects continue, new sprouts emerge next to a
                stump of a tree chopped down. Beginnings and endings bleed into
                each other without clear demarcations. 
                <br></br>
                <br></br>
                When I asked what he would do after Continental Electronics
                closes, Mr Khoo, who has been with the store since it started
                more than 30 years ago, let out a chuckle. He doesn’t entertain
                what is not-yet-here: “I don’t spend energy thinking about that.
                I will just continue doing what I do now until I cannot do it
                anymore, then I will start thinking what to do after.” He simply
                continues working at and in the world until it transforms. Loss
                is not on his mind as something to pre-empt, but something to
                adapt to and live alongside in real time. 
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                Another place that has been an enduring site of nurturing an
                idea into fruition is Wayman Enterprise, by Cavan Road. Run by
                Mr Phua for over thirty years, the workshop space is punctuated
                by a suspended boxing bag weighed down by dust and a table
                filled with tools roughed by iron oxide. Those working in the
                fields of theatre and film know him as the set builder who is
                able to make magic happen, with his self-taught carpentry skills
                that deftly transforms designs and ideas into incredibly
                intricate sculptures. 
                <br></br>
                <br></br>
                Stepping into Mr Phua’s workshop space, there wasn’t the
                disorientation I felt in Sim Lim Tower. He was working away at a
                spear for a deity figurine when I arrived, a familiar scene. I
                first met him months ago when I was in the area with a dear
                friend, and we shared an afternoon chatting about life, aging,
                and the state of the world. This was my first time asking him
                about his work, and he confessed that it had been a while since
                he received a commission for either film or theatre. 
                <br></br>
                <br></br>
                “People now outsource to cheaper options, or they do it
                themselves,” he said, and most of his time now is spent on
                projects that seemingly have no throughline except for his
                interest and ability. Mr Phua shared more about large scale
                projects that he felt especially proud of, particularly the{" "}
                <a
                  href="https://www.facebook.com/photo.php?fbid=354278261734344&id=295494610946043&set=a.296887534140084"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="body-text-link OE-BodyAN"
                >
                  giant bridge he built for the Loyang Tua Pek Kong temple in
                  2018
                </a>
                . As he explained how he had constructed the bridge, I learnt
                that what makes a structure warp-resistant is its ability to
                move. It is not rigidity, or resistance, but malleability that
                gives something the ability to stay close to its original
                structural intention. Rather than one continuous piece of
                material, Mr Phua told me that he used multiple individual
                wooden blocks, closely packed vertically, to create genuine
                stability. To stack upon each other without bending, each piece
                of wood must maintain its strength and integrity. 
                <br></br>
                <br></br>
                Our conversation meandered, and it became clear that he, like Mr
                Khoo, doesn’t give much thought to what comes after—”there is no
                ending. What people call ending, to me just signals the start of
                the next thing. I will just do what I enjoy doing until I stop.
                For now, I enjoy killing time, taking up projects that I can do,
                scrolling TikTok, talk to friends.”
                <br></br>
                <br></br>
                When asked if he missed the projects he used to do, he waved my
                question away as if it were a fly and laughed—”I learn something
                from each project and I bring it to the next thing. Even after a
                project ends, it just means I gain more time. There is nothing
                to miss, I just live my life the way I want.” And when I asked
                what comes after this, if he would ever sell the workshop and
                retire, he laughed again—”I don’t know and I don’t need to know.
                I will never sell this place, it is my second home and I need a
                place to go more than the extra money it will get me.” 
                <br></br>
                <br></br>
                The workshop space houses more than his work, it’s also where he
                spends hours tinkering and dreaming about how projects can
                materialise. Gaston Bechelard, in writing about the Poetics of
                Space, speaks precisely to this: how “the house shelters
                daydreaming, the house protects the dreamer, the house allows
                one to dream in peace.” The physical space of Mr Phua’s workshop
                space has allowed him to not just dream in peace, but to enliven
                the designs that would otherwise remain two-dimensional, on
                paper. It has made his dreams real, tangible. 
              </span>
            </div>
          </div>
          <span className="to-dwell-a-little-longer-text189 OE-SubheadingF25">
            Without the space housing these impulses, there will be no where for
            the dust to settle and concretise.
            <br></br>
            <br></br>
            Instead, what happens is a dispersal where these dreamings would
            find other landing spots to take root. Where and how exactly, is
            unknown.
            <br></br>
            <br></br>
            But I am curious, and so I ask, again. Mr Phua gave me a piercing
            look, took a drag of his cigarette, before relenting: “If you really
            want to know, I will say look back into the future. Everything is
            cyclical, there is no ‘after’ this.” 
          </span>
          <div className="to-dwell-a-little-longer-editorial-r6">
            <div className="to-dwell-a-little-longer-editorial-r3l2">
              <img
                alt="image"
                src="/oddsandends/oe-10-circuitboard.png"
                loading="eager"
                className="to-dwell-a-little-longer-image23"
              />
            </div>
            <div className="to-dwell-a-little-longer-editorial-r3r2">
              <span className="body-para-text OE-BodyAN">
                There is resonance here, given that there has{" "}
                <a
                  href="https://sg.finance.yahoo.com/news/sim-lim-tower-owners-hopeful-122830057.html"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="body-text-link OE-BodyAN"
                >
                  been a failed attempt to sell Sim Lim Tower
                </a>
                .<br></br>
                <br></br>
                From the meandering hours talking to Mr Phua, there is an
                insistence to being hyper present in the current moment that
                revolts against any exploration of what is not-yet here. Reality
                and life as is, is created in the moment, the path revealing
                itself as we walk. I think of what Pablo Fernandez Velasco
                writes about{" "}
                <a
                  href="https://philpapers.org/rec/FEREGA-6"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="body-text-link OE-BodyAN"
                >
                  dwelling in the context of ecological grief
                </a>
                , about what we need to feel at home with our lives when so much
                is shifting sands. He wrote that “the places in and through
                which we dwell constrain what seems possible and meaningful to
                us but always with a certain degree of indeterminacy.” The
                places in which Mr Phua and the staff at Continental Electronics
                dwell are comfortably pregnant with indeterminacy, with future
                anxieties staying where they are, in the future. The current
                moment is so full with what needs to get done; any possibility
                of an absence is just part of the process, a non-event.
              </span>
            </div>
          </div>
          <div className="to-dwell-a-little-longer-editorial-r7">
            <img
              alt="image"
              src="/oddsandends/oe-tdall_05.webp"
              loading="eager"
              className="to-dwell-a-little-longer-image24"
            />
            <span className="to-dwell-a-little-longer-text209 OE-FootnoteF25">
              Auntie Geok Eng and a customer’s hands at the counter of
              Continental Electronics
            </span>
          </div>
          <span className="body-para-text-alt OE-BodyAN">
            To go on in the presence of an absence, to experience negative space
            as a thing of its own, requires multiple acts of confrontational
            imagination. By which I mean, imagination that frictions against our
            usual intuitive routes of focusing on the object that has departed
            rather than recognising the vacuum (and what rushes in to fill it)
            as something of its own right. Loss, paradoxically, is not nothing. 
            <br></br>
            <br></br>
            Let’s return to Sim Lim Tower, to where we first started, in
            Continental Electronics. In a conversation with artist Chok Si Xuan,
            a long-time visitor and customer at the store, she shared that it
            was there where she first nurtured buds of her ideas into concrete
            builds. She had first visited the store out of proximity, given how
            near her university was to Continental. Through the years, her
            knowledge of electronics and parts has grown, and yet she continues
            to visit the store.
            <br></br>
            <br></br>
            With the advent of e-commerce, she would be able to get the same
            parts for a fraction of the price. But she would need to know
            exactly what parts are needed, and in that rigid certainty, what is
            lost is the margins that make surprises possible. At Continental,
            even if she already has a relatively concrete idea of how she wants
            to work with the circuit, she can be surprised by suggestions made
            by Auntie Geok Eng, whom she has spoken with the most in the years
            she has frequented the store. Despite it being years after her
            graduation from university, each time she enters the store it feels
            like she is “transport[ed] back to a time where [she] is still
            trying [to] figure things out in school.” 
            <br></br>
            <br></br>
            Si Xuan’s practice works with electronics and parts that are found
            and recast in surprising contexts, and we spoke at length about the
            life of an object after a period of ownership and visibility. She
            seldom discards anything that was part of her work. Instead,
            whenever she procures something, it is with the intention that it
            will be with her for a while until it stops making sense, then it
            can “go and have a better life somewhere else.”
            <br></br>
            <br></br>
            We cannot know what will happen to something after we relinquish it,
            just as we don’t know what exact processes led an object into our
            hands. In reading and thinking about afterlives and ending, this
            inability to know creates an opacity that prevents comprehension. At
            least, prevents the same sort of comprehension we had access to when
            the object was within reach.
          </span>
          <div className="to-dwell-a-little-longer-editorial-r8">
            <img
              alt="image"
              src="/oddsandends/oe-tdall_06.webp"
              loading="eager"
              className="to-dwell-a-little-longer-image25"
            />
            <span className="to-dwell-a-little-longer-text227 OE-FootnoteF25">
              Arduino sample set up at Continental Electronics
            </span>
          </div>
          <span className="body-para-text-cen OE-BodyAN">
            The truth is, there is no telling how things will unfold from here.
            The end might not arrive in the way we expect, it might arrive
            quietly, or imperceptibly, or not at all. What happens in the
            afterlives of these practices, of how we have grown to entwine with
            these physical spaces? The work of re-orientation, or
            dis-orientation, requires a sandbox generous enough to stretch
            beyond the edges of what we habitually know. 
            <br></br>
            <br></br>
            Within the practice of creating spaciousness, to hope is not about
            endurance, but perhaps more about dancing in the scattered aftermath
            of an ending without rushing towards the next beginning prematurely,
            knowing that sense will arrive in time as we are dancing. And then,
            perhaps, build and find other trailheads.
          </span>
          <span className="blue-text OE-BodyF25">About Teo Xiao Ting</span>
          <span className="body-para-text-end OE-BodyAN">
            <em>
              Xiao Ting works in intimate and sometimes invisible spaces to
              sprout worlds that are more joyous, habitable, and honest.
              Presently, their practice materialises as arts writing, workshop
              facilitation, and one-on-one therapy sessions. They are committed
              to CITRUS practices, a working group of arts practitioners
              exploring ways to build towards better practices around care and
              intimacy in artmaking. She is currently an affiliate clinical
              mental health and somatic practitioner at Sol Therapy, and runs an
              intimate practice named Rehearsal Room.
            </em>
          </span>
          <div className="to-dwell-a-little-longer-editorial-r9">
            <img
              alt="image"
              src="/oddsandends/oe-08-pillar.png"
              loading="eager"
              className="to-dwell-a-little-longer-image26"
            />
            <img
              alt="image"
              src="/oddsandends/oe-08-pillar.png"
              loading="eager"
              className="to-dwell-a-little-longer-image27"
            />
            <img
              alt="image"
              src="/oddsandends/oe-08-pillar.png"
              loading="eager"
              className="to-dwell-a-little-longer-image28"
            />
            <img
              alt="image"
              src="/oddsandends/oe-08-pillar.png"
              loading="eager"
              className="to-dwell-a-little-longer-image29"
            />
          </div>
        </div>
      </div>
      <div className="to-dwell-a-little-longer-footer-container">
        <Footer
          link={
            <Fragment>
              <span className="to-dwell-a-little-longer-text236">
                © 2025 by Feelers. All Rights Reserved. Feelers is a project
                housed within Potato Productions.
              </span>
            </Fragment>
          }
          link1={
            <Fragment>
              <span className="to-dwell-a-little-longer-text237">
                More information about our Privacy Policy.
              </span>
            </Fragment>
          }
          rootClassName="footerroot-class-name31"
        ></Footer>
      </div>
    </div>
  );
};

export default ToDwellALittleLonger;
