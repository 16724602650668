import React, { Fragment } from "react";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import Profile from "../components/profile";
import Footer from "../components/footer";
import "./about.css";

const About = (props) => {
  return (
    <div className="about-container">
      <Helmet>
        <title>About - Feelers 2025</title>
        <meta property="og:title" content="About - Feelers 2025" />
      </Helmet>
      <div className="about-bg-container">
        <img src="/external/bgcircle.png" className="about-image1" />
        <img src="/external/bgcircle.png" className="about-image2" />
        <img src="/external/bgcircle.png" className="about-image3" />
      </div>
      <div className="about-navbar-container">
        <Navbar rootClassName="navbarroot-class-name"></Navbar>
      </div>
      <div className="about-body-container">
        <div className="about-top-container">
          <div className="about-header-container">
            <div className="about-top-row">
              <img
                src="/external/feelerslogolong.webp"
                className="about-image4"
              />
              <span className="about-heading1 Body-XXL">
                <span>is an</span>
                <br></br>
              </span>
            </div>
            <span className="about-heading2 Body-XXL">
              art &amp; technology
            </span>
            <span className="about-heading3 Body-XXL">research lab.</span>
          </div>
        </div>
        <div className="about-bottom-container">
          <div className="about-title-container">
            <h1 className="about-heading4 Title-M">THE TEAM</h1>
          </div>
          <div className="about-row1">
            <div className="about-profile-slot10">
              <Profile
                profileBio="Ashley is a visual artist who utilises kinetic sculpture and installation to create sensory spaces and generative experiences through algorithm and design."
                profileImg="/external/pp-02.webp"
                profileName="Ashley Hi"
                profileTitle="Director &amp; Tech Lead"
                profileInterests="Interests: permacomputing, bartending, unboxing timelapses"
              ></Profile>
              <div className="about-socials1">
                <a
                  href="https://www.ashley-hi.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="about-link1"
                >
                  <svg viewBox="0 0 1024 1024" className="about-website1">
                    <path d="M810 768q52 0 90-38t38-90-38-90-90-38h-64v-22q0-98-68-166t-166-68q-80 0-142 48t-84 122h-30q-70 0-120 51t-50 121 50 120 120 50h554zM826 428q82 6 140 67t58 145q0 88-63 151t-151 63h-554q-106 0-181-75t-75-181q0-94 67-169t161-85q42-78 118-126t166-48q108 0 201 76t113 182z"></path>
                  </svg>
                </a>
                <a
                  href="https://instagram.com/ashleyzhiqi"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="about-link2"
                >
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="about-instagram1"
                  >
                    <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                  </svg>
                </a>
              </div>
            </div>
            <div className="about-profile-slot11">
              <Profile
                profileBio="ants writes, directs, and performs. An amateur adult, they’re also a baby drag king and chaotic home cook. Their work investigates domesticity, attention, and relational intimacies."
                profileImg="/external/pp-03.webp"
                profileName="ants chua"
                profileTitle="Content &amp; Programmes Lead"
                profileInterests="Interests: creative non-fiction, grocery shopping, teh bing gao siew2 dai, delight and a good tee hee"
              ></Profile>
            </div>
            <div className="about-profile-slot12">
              <Profile
                profileBio="Alysha is an occasional essay writer, frequent powerpoint maker and full-time overthinker. She also manages NIMBUS, a new network for independent media."
                profileImg="/external/pp-04.webp"
                profileName="Alysha Chandra"
                profileTitle="Marketing Lead"
                profileInterests="Interests: longkangs, falling off skateboards, the element of surprise"
              ></Profile>
              <div className="about-socials2">
                <a
                  href="https://instagram.com/alyalychandra"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="about-link3"
                >
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="about-instagram2"
                  >
                    <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                  </svg>
                </a>
              </div>
            </div>
            <div className="about-profile-slot13">
              <Profile
                profileBio="Azure is a multi-disciplinary dreamer and creative. Her practice is led by the human experience, perpetual exploration and meaningful exchange."
                profileImg="/external/pp-05.webp"
                profileName="Azure Chaya"
                profileTitle="Design Lead"
                profileInterests="Interests: sound &amp; film; ephemera; moon phases; big laughs and good soups"
              ></Profile>
              <div className="about-socials3">
                <a
                  href="https://aokhai.cargo.site/"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="about-link4"
                >
                  <svg viewBox="0 0 1024 1024" className="about-website2">
                    <path d="M810 768q52 0 90-38t38-90-38-90-90-38h-64v-22q0-98-68-166t-166-68q-80 0-142 48t-84 122h-30q-70 0-120 51t-50 121 50 120 120 50h554zM826 428q82 6 140 67t58 145q0 88-63 151t-151 63h-554q-106 0-181-75t-75-181q0-94 67-169t161-85q42-78 118-126t166-48q108 0 201 76t113 182z"></path>
                  </svg>
                </a>
                <a
                  href="https://instagram.com/ao.khai"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="about-link5"
                >
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="about-instagram3"
                  >
                    <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                  </svg>
                </a>
              </div>
            </div>
            <div className="about-profile-slot14"></div>
          </div>
          <div className="about-row2">
            <div className="about-profile-slot15"></div>
            <div className="about-profile-slot16"></div>
            <div className="about-profile-slot17"></div>
            <div className="about-profile-slot18 Body-M"></div>
          </div>
          <div className="about-profile-slot19"></div>
          <div className="about-row3">
            <div className="about-text-container">
              <span className="about-text12 Body-M">
                <span>
                  Feelers is a research lab of artists and designers focused on
                  the intersections of art and technology. This research takes
                  the form of interdisciplinary artworks, programmes, and
                  education initiatives.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <br className="Body-M"></br>
                <br className="Body-M"></br>
                <span>
                  Our projects are invitations to think critically and
                  empathetically about the world we live in; and to build a
                  community engaged in learning and working together. We also
                  extend these invitations inward through resilient and flexible
                  structures that allow employees to continue their independent
                  practices.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <br className="Body-M"></br>
                <br className="Body-M"></br>
                <span>
                  Feelers is a bid for another world to come into being.
                  Glimpses of it can be found in the ways we practice sustaining
                  ourselves; in the ways we choose to listen instead of turn
                  away; and in the ways we question existing paradigms of
                  acceleration and extraction.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <br className="Body-M"></br>
                <br className="Body-M"></br>
                <span>
                  While art and technology form key fields of research, they
                  continually lead us back to one another. It is with one
                  another that our real work takes place.
                </span>
                <br className="Body-M"></br>
              </span>
              <a
                href="https://www.potatoproductions.com/"
                target="_blank"
                rel="noreferrer noopener"
                className="about-link6 Body-M"
              >
                <span>We are housed within Potato Productions.</span>
                <br></br>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="about-footer-container">
        <Footer
          link={
            <Fragment>
              <span className="about-text26">
                © 2025 by Feelers. All Rights Reserved. Feelers is a project
                housed within Potato Productions.
              </span>
            </Fragment>
          }
          link1={
            <Fragment>
              <span className="about-text27">
                More information about our Privacy Policy.
              </span>
            </Fragment>
          }
          rootClassName="footerroot-class-name29"
        ></Footer>
      </div>
    </div>
  );
};

export default About;
