import React, { Fragment } from "react";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import Footer from "../components/footer";
import "./privacy-policy.css";

const PrivacyPolicy = (props) => {
  return (
    <div className="privacy-policy-container">
      <Helmet>
        <title>Privacy Policy - Feelers 2025</title>
        <meta property="og:title" content="Privacy Policy - Feelers 2025" />
      </Helmet>
      <div className="privacy-policy-bg-container">
        <img src="/external/bgcircle.png" className="privacy-policy-image1" />
        <img src="/external/bgcircle.png" className="privacy-policy-image2" />
        <img src="/external/bgcircle.png" className="privacy-policy-image3" />
      </div>
      <div className="privacy-policy-navbar-container">
        <Navbar navImgSrc="/feelers/feelerslogo.png"></Navbar>
      </div>
      <div className="privacy-policy-body-container">
        <span className="privacy-policy-header Title-XXXL">Privacy Policy</span>
        <div className="privacy-policy-col-container">
          <div className="privacy-policy-col1-container">
            <span className="privacy-policy-text100">1. Overview</span>
            <span className="privacy-policy-text101 Body-XS">
              <span>
                We, Potato Productions Pte. Ltd. (the Company), place great
                importance on the confidentiality, privacy and protection of
                your personal data. We handle all personal data provided to us
                in accordance with the Singapore Personal Data Protection Act
                (No. 26 of 2012) and the subsidiary legislation made under it,
                and where applicable, the European General Data Protection
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>Regulation 2016/679.</span>
              <br className="Body-XS"></br>
              <br className="Body-XS"></br>
              <span>
                {" "}
                BY USING THIS WEBSITE, YOU AGREE THAT THE COMPANY MAY COLLECT,
                USE, PROCESS, STORE AND/OR DISCLOSE YOUR PERSONAL DATA IN
                ACCORDANCE WITH THE TERMS OF THIS PRIVACY POLICY. IF YOU
                DISAGREE WITH ALL OR ANY PART OF THIS PRIVACY POLICY, PLEASE DO
                NOT USE OR CONTINUE ANY FURTHER USE OF
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>THIS WEBSITE.</span>
            </span>
            <span className="privacy-policy-text108">
              2. Information we collect
            </span>
            <span className="privacy-policy-text109 Body-XS">
              <span>
                2.1 When you access our website www.potatoproductions.com and
                all pages within the domain (the “Website”), the following types
                of information may be collected from you:
              </span>
              <br className="Body-XS"></br>
              <br className="Body-XS"></br>
              <span>
                2.1.1 Contact Information: These relate to the following
                information about you, some or all of which may be collected
                when you contact us or our representatives:
              </span>
              <br className="Body-XS"></br>
              <br className="Body-XS"></br>
              <span>(a) Name</span>
              <br className="Body-XS"></br>
              <span>(b) Name of organisation</span>
              <br className="Body-XS"></br>
              <span>(c) Residential address</span>
              <br className="Body-XS"></br>
              <span>(d) Contact telephone number</span>
              <br className="Body-XS"></br>
              <span>(e) Email address</span>
              <br className="Body-XS"></br>
              <br className="Body-XS"></br>
              <span>
                2.1.2 Access Information: These relate to information about your
                computer or mobile device and your access patterns, some or all
                of which may be collected when you use the Website:
              </span>
              <br className="Body-XS"></br>
              <br className="Body-XS"></br>
              <span>(a) IP address</span>
              <br className="Body-XS"></br>
              <span>(b) Geographic location</span>
              <br className="Body-XS"></br>
              <span>(c) Operating system and browser type</span>
              <br className="Body-XS"></br>
              <span>(d) Device type</span>
              <br className="Body-XS"></br>
              <span>(e) Web traffic data</span>
              <br className="Body-XS"></br>
              <span>(f) Time spent on the Website</span>
              <br className="Body-XS"></br>
              <span>(g) Number of webpages within the Website viewed</span>
              <br className="Body-XS"></br>
              <span>
                (h) Access from third party websites linking to our Website
              </span>
              <br className="Body-XS"></br>
              <span>
                (i) Advertising information, e.g. what ads were clicked and/or
                viewed
              </span>
              <br className="Body-XS"></br>
              <br className="Body-XS"></br>
              <span>
                The company makes use of third-party analytic tools such as
                Google Analytics and Facebook Pixel. More information about how
                your personal data is collected and processed using these
                third-party analytic tools can be found at:
                https://marketingplatform.google.com/about/analytics/,
                https://www.facebook.com/business/learn/facebook-ads-pixel
              </span>
              <br className="Body-XS"></br>
              <br className="Body-XS"></br>
              <span>
                This website makes use of cookies to improve the site and to
                provide you with a more tailored user experience. A cookie is a
                small file that may be added to your device when you visit the
                Website, and which is sent back to the Website when you visit it
                again. The cookies we employ on the Website do not grant any
                access to your computer or any information about you, other than
                the data you choose to share with us.
              </span>
              <br className="Body-XS"></br>
              <br className="Body-XS"></br>
              <span>We use the following categories of cookies:</span>
              <br className="Body-XS"></br>
              <br className="Body-XS"></br>
              <span>
                (a) Strictly necessary cookies: These are cookies that are
                required for the operation of the Website. Specifically, we run
                the cookies for the following purposes:
              </span>
              <br className="Body-XS"></br>
              <span>(i) To verify that you’re not a robot; and</span>
              <br className="Body-XS"></br>
              <span>(ii) To remember preferences that you have set.</span>
              <br className="Body-XS"></br>
              <br className="Body-XS"></br>
              <span>
                While you may disable these cookies in your internet browser
                settings, doing so may result in restrictions in your use of the
                Website and/or the failure of the Website to work properly for
                you.
              </span>
              <br className="Body-XS"></br>
              <br className="Body-XS"></br>
              <span>
                (b) Analytical/Performance/functionality/advertising cookies:
                These cookies enable us to collect information about how users
                use our Website, and to personalize our content and
                advertisements for you. Specifically, we use these cookies to:
              </span>
              <br className="Body-XS"></br>
              <br className="Body-XS"></br>
              <span>
                (i) Perform data analytics of how users use the Website, in
                order for us to improve the Website, our content and services;
              </span>
              <br className="Body-XS"></br>
              <span>
                (ii) Recommend other content on the Website which you may be
                interested in; and
              </span>
              <br className="Body-XS"></br>
              <span>(iii) Provide you with targeted advertisements.</span>
              <br className="Body-XS"></br>
              <br className="Body-XS"></br>
              <span>
                While you may disable these cookies in your internet browser
                settings, please note that you will still receive
                recommendations for Website content, and that you will still see
                the same number of advertisements on the Website, except that
                these will not be tailored to you based on your personal
                information.
              </span>
              <br className="Body-XS"></br>
              <br className="Body-XS"></br>
              <span>
                If you disagree with our use of cookies, you may choose to stop
                using the Website, or to disable cookies through your internet
                browser settings at any time. If you do neither, we will take it
                that you have consented to our use of cookies on the Website.
              </span>
            </span>
            <span className="privacy-policy-text182">
              3. Our collection, use and disclosure of personal data
            </span>
            <span className="privacy-policy-text183 Body-XS">
              <span>
                3.1 Insofar as the information collected allows you to be
                identified directly, or indirectly when combined with other
                information, it will be deemed “Personal Data”.
              </span>
              <br className="Body-XS"></br>
              <br className="Body-XS"></br>
              <span>
                3.2 We may collect, use, process, store and/or disclose your
                Personal Data, for the following purposes, where necessary or
                applicable:
              </span>
              <br className="Body-XS"></br>
              <br className="Body-XS"></br>
              <span>(a) To verify your identity;</span>
              <br className="Body-XS"></br>
              <span>
                (b) To enable your device and/or software to access the Website;
              </span>
              <br className="Body-XS"></br>
              <span>
                (c) For the smooth administration and improvement of the
                Website, including troubleshooting, site analysis, testing,
                research, statistical and survey purposes and to obtain feedback
                so as to enable us to improve the operation of the Website and
                offer you a better user experience;
              </span>
              <br className="Body-XS"></br>
              <span>
                (d) For audit, customer service, administrative support, market
                research and business development purposes;
              </span>
              <br className="Body-XS"></br>
              <span>
                (e) To contact or notify you about matters relating to updates
                and/or developments to the Website, the Website Terms of Use,
                Cookies Policy and this Privacy Policy;
              </span>
              <br className="Body-XS"></br>
              <span>
                (f) sending you marketing information about our goods or
                services including notifying you of our marketing events,
                initiatives and promotions, lucky draws, membership and rewards
                schemes and other promotions;
              </span>
              <br className="Body-XS"></br>
              <span>
                (g) To respond to any queries, requests or reports which you may
                have submitted;
              </span>
              <br className="Body-XS"></br>
              <span>
                (h) To perform a contract to which you are a party to or deliver
                the services you have requested;
              </span>
              <br className="Body-XS"></br>
              <span>
                (i) To recommend and/or display content and advertisements on
                the Website that may be of interest to you;
              </span>
              <br className="Body-XS"></br>
              <span>
                (j) To comply with the requirements under any applicable law or
                requests from any competent authority, including but not limited
                to relevant governmental authorities, law enforcement agencies
                and regulatory bodies;
              </span>
              <br className="Body-XS"></br>
              <span>
                (k) Where required in any civil or criminal suit or for the
                protection and/or enforcement of our legal rights and
                obligations;
              </span>
              <br className="Body-XS"></br>
              <span>
                (l) In the event we sell or buy any business or assets, where
                necessary for the purposes of the sale or purchase; and/or
              </span>
              <br className="Body-XS"></br>
              <span>
                (m) To enable any third parties to perform any of the above
                where necessary and/or applicable, including but not limited to
                law enforcement agencies, regulatory bodies, our business
                partners, affiliates, suppliers and sub-contractors.
              </span>
              <br className="Body-XS"></br>
              <span>
                (n) To post testimonials (with your consent). We post
                testimonials on our Website that may contain personal
                information. Prior to posting a testimonial, we will obtain your
                consent to use your name and testimonial.
              </span>
              <br className="Body-XS"></br>
              <span>
                (o) Any other purpose for which you have provided us the
                information.
              </span>
              <br className="Body-XS"></br>
              <br className="Body-XS"></br>
              <span>
                If we intend to use your Personal Data for any purpose not
                listed above, we will notify you of this purpose and obtain your
                consent, unless otherwise prescribed by any applicable law.
              </span>
              <br className="Body-XS"></br>
              <br className="Body-XS"></br>
              <span>
                3.3 We will retain your Personal Data for the length of time
                required to fulfil the purposes for which the Personal Data was
                collected, save where continued retention is necessary for us to
                comply with our other legal obligations or to meet our business
                requirements.
              </span>
              <br className="Body-XS"></br>
              <br className="Body-XS"></br>
              <span>
                3.4 In respect of the transfer of data to third parties, we will
                do so only with your consent and ensure that your Personal Data
                is transferred to recipients who are legally bound to uphold
                strong data protection standards and to comply with applicable
                data protection laws and this Privacy Policy.
              </span>
              <br className="Body-XS"></br>
              <br className="Body-XS"></br>
              <span>
                We generally do not transfer your personal data to countries
                outside of Singapore. However, if we do so, we will obtain your
                consent for the transfer to be made and we will take steps to
                ensure that your personal data continues to receive a standard
                of protection that is at least comparable to that provided under
                the PDPA.
              </span>
              <br className="Body-XS"></br>
              <br className="Body-XS"></br>
              <span>
                3.5 The Website may contain links to third party websites which
                are not subject to this Privacy Policy. Websites of Potato
                Productions’ subsidiaries and partner companies are included
                under this third party definition. We are not responsible for
                their collection and use of personal information, or their data
                security and integrity practices and procedures.
              </span>
            </span>
          </div>
          <div className="privacy-policy-col2-container">
            <span className="privacy-policy-text234">
              4. Your rights in respect to your personal data
            </span>
            <span className="privacy-policy-text235 Body-XS">
              <span>
                4.1 We generally rely on personal data provided by you (or your
                authorised representative). In order to ensure that your
                personal data is current, complete and accurate, please update
                us if there are changes to your personal data. You may write to
                our Data Protection Officer at dpo@potatoproductions.com to:
              </span>
              <br className="Body-XS"></br>
              <br className="Body-XS"></br>
              <span>(a) Correct or update your Personal Data;</span>
              <br className="Body-XS"></br>
              <span>
                (b) Reasonably request for access to your Personal Data. Do note
                that you may face reasonable charges for our provision of the
                Personal Data;
              </span>
              <br className="Body-XS"></br>
              <span>
                (c) Request for information relating to our use or disclosure of
                your Personal Data;
              </span>
              <br className="Body-XS"></br>
              <span>
                (d) Withdraw your consent and request that we stop processing
                your Personal Data for certain purposes;
              </span>
              <br className="Body-XS"></br>
              <span>
                (e) Delete any Personal Data we no longer have legally
                permissible grounds to use; or
              </span>
              <br className="Body-XS"></br>
              <span>
                (f) Make a report about any actual or potential breach of
                security in relation to your Personal Data.
              </span>
              <br className="Body-XS"></br>
              <br className="Body-XS"></br>
              <span>
                We will respond to your request as soon as reasonably possible.
                Should we not be able to respond to your request within thirty
                (30) calendar days after receiving your request, we will inform
                you in writing, within thirty (30) days, of the time by which we
                will be able to respond to your request.
              </span>
              <br className="Body-XS"></br>
              <br className="Body-XS"></br>
              <span>
                Do note that we may not be obliged to comply with your requests
                under certain conditions as prescribed by the applicable law. In
                such an event, we will notify you accordingly of the basis for
                not acceding to your request.
              </span>
            </span>
            <span className="privacy-policy-text256">
              5. Data protection and security
            </span>
            <span className="privacy-policy-text257 Body-XS">
              <span>
                5.1 We will put appropriate and reasonable technical and
                administrative security measures in place to help ensure that
                your information is protected against unauthorised or accidental
                access, use, alteration, disclosure or loss.
              </span>
              <br className="Body-XS"></br>
              <br className="Body-XS"></br>
              <span>
                You should be aware, however, that no method of transmission
                over the Internet or method of electronic storage is completely
                secure. While security cannot be guaranteed, we strive to
                protect the security of your information and are constantly
                reviewing and enhancing our information security measures.
              </span>
              <br className="Body-XS"></br>
              <br className="Body-XS"></br>
              <span>
                5.2 All of our employees who deal with Personal Data are trained
                in the proper handling and protection of Personal Data.
              </span>
              <br className="Body-XS"></br>
              <br className="Body-XS"></br>
              <span>
                5.3 The Company uses Google’s cloud storage services which may
                store your personal data.
              </span>
            </span>
            <span className="privacy-policy-text268">6. Variation</span>
            <span className="privacy-policy-text269 Body-XS">
              <span>
                6.1 We reserve the right to change this Privacy Policy from time
                to time at our sole and absolute discretion. The most updated
                Privacy Policy will be made available promptly on the Website
                and you will be notified of the update. It is your
                responsibility to keep yourself updated and aware of the latest
                version of this Privacy Policy. If any change is unacceptable to
                you, you should immediately cease all further use of the
                Website. If you continue using the Website after the changes
                take effect, you will be taken to have accepted
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <br></br>
              <span>the changes.</span>
              <br></br>
            </span>
            <span className="privacy-policy-text274">
              DATA PROTECTION OFFICER (DPO)
            </span>
            <span className="privacy-policy-text275 Body-XS">
              <span>
                You may contact our Data Protection Officer if you have any
                enquiries or feedback on our privacy policies, or if you wish to
                make any request. Please contact our DPO at:
              </span>
              <br className="Body-XS"></br>
              <span>dpo@potatoproductions.com</span>
              <br className="Body-XS"></br>
            </span>
          </div>
        </div>
      </div>
      <div className="privacy-policy-footer-container">
        <Footer
          link={
            <Fragment>
              <span className="privacy-policy-text280">
                © 2025 by Feelers. All Rights Reserved. Feelers is a project
                housed within Potato Productions.
              </span>
            </Fragment>
          }
          link1={
            <Fragment>
              <span className="privacy-policy-text281">
                More information about our Privacy Policy.
              </span>
            </Fragment>
          }
          rootClassName="footerroot-class-name"
        ></Footer>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
