import React, { useState } from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import "./navbar-b.css";

const NavbarB = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <div className={`navbar-b-navbar ${props.rootClassName} `}>
      <Link to="/" className="navbar-b-navlink">
        <img src="/feelers/feelerslogoblack.png" className="navbar-b-logo" />
      </Link>
      <div className="navbar-b-links-container">
        <Link to="/about" className="navbar-b-link1 Navbar-Link">
          About
        </Link>
        <Link to="/archive" className="navbar-b-link2 Navbar-Link">
          Work
        </Link>
        <Link to="/contact" className="navbar-b-link3 Navbar-Link">
          Contact
        </Link>
        <a
          href="https://feelers.bigcartel.com"
          target="_blank"
          rel="noreferrer noopener"
          className="navbar-b-link4 Navbar-Link"
        >
          Shop
        </a>
      </div>
    </div>
  );
};

NavbarB.defaultProps = {
  rootClassName: "",
};

NavbarB.propTypes = {
  rootClassName: PropTypes.string,
};

export default NavbarB;
