import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";

import NavbarB from "../components/navbar-b";
import Footer from "../components/footer";
import "./home.css";

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Feelers 2025</title>
        <meta property="og:title" content="Feelers 2025" />
      </Helmet>
      <div className="home-bg-container">
        <img
          alt="image"
          src="/oddsandends/oe-01-decorl.png"
          className="home-image10"
        />
        <img
          alt="image"
          src="/oddsandends/oe-02-decorr.png"
          className="home-image11"
        />
      </div>
      <div className="home-navbar-container">
        <NavbarB rootClassName="navbar-broot-class-name1"></NavbarB>
      </div>
      <div className="home-landingessay">
        <img
          alt="image"
          src="/oddsandends/oe-03-logo.png"
          className="home-image12"
        />
        <div className="home-landing">
          <img
            alt="image"
            src="/oddsandends/oe-04-title.gif"
            className="home-image13"
          />
          <div className="home-landing-row">
            <div className="home-landing-l">
              <span className="home-text100 OE-SubheadingAN">seasons 2025</span>
              <img
                alt="image"
                src="/oddsandends/oe-05-arrows.webp"
                className="home-image14"
              />
            </div>
            <div className="home-landing-r">
              <img
                alt="image"
                src="/oddsandends/oe-06-blue.png"
                loading="eager"
                className="home-image15"
              />
            </div>
          </div>
        </div>
        <div className="home-essay">
          <div className="home-essay-body">
            <div className="home-essay-bg">
              <img
                alt="image"
                src="/oddsandends/oe-07-metalangle.png"
                loading="eager"
                className="home-image16"
              />
            </div>
            <div className="home-essay-text">
              <span className="home-text101 OE-BodyAN">
                <b>Odds And Ends </b>
                is Feelers’ 2025 Season—an annual series of interdisciplinary
                programmes that flow along circuits and fault lines between art
                and technology.
                <br></br>
                <br></br>
                <span>
                  This season, we examine trivial pursuits and ephemera. We
                  accept consequence and pursue the inconsequential. Amidst a
                  paradigm of constant innovation and clean solutions, we look
                  at aftershocks, aftermaths, and the detritus left behind. 
                </span>
                <br></br>
              </span>
              <Link to="/reflective-essay" className="home-navlink1 OE-BodyF25">
                <span>an introduction &gt;&gt;&gt;</span>
                <br></br>
              </Link>
            </div>
          </div>
          <img
            alt="image"
            src="/oddsandends/oe-08-pillar.png"
            className="home-image17"
          />
        </div>
      </div>
      <div className="home-editorial">
        <div className="home-editorial-bg">
          <div className="home-editorial-bg-l">
            <img
              alt="image"
              src="/oddsandends/oe-08-pillar.png"
              loading="eager"
              className="home-image18"
            />
            <img
              alt="image"
              src="/oddsandends/oe-08-pillar.png"
              loading="eager"
              className="home-image19"
            />
            <img
              alt="image"
              src="/oddsandends/oe-08-pillar.png"
              loading="eager"
              className="home-image20"
            />
            <img
              alt="image"
              src="/oddsandends/oe-08-pillar.png"
              loading="eager"
              className="home-image21"
            />
          </div>
          <img
            alt="image"
            src="/oddsandends/oe-06-blue.png"
            loading="eager"
            className="home-image22"
          />
          <div className="home-mask1"></div>
        </div>
        <div className="home-editorial-body">
          <span className="home-text109 OE-SubheadingAN">
            <span>editorial</span>
            <br></br>
          </span>
          <span className="program-head-text OE-Headline">
            <span>
              to dwell
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <br></br>
            <span>a little longer</span>
            <br></br>
          </span>
          <span className="home-text117 OE-BodyAN">
            <span>
              An editorial by Teo Xiao Ting that profiles technicians whose
              practices of repair and maintenance live across a scatter of
              places and modes, ungovernable and unsettled.
            </span>
            <br></br>
          </span>
          <Link
            to="/to-dwell-a-little-longer"
            className="home-navlink2 OE-BodyF25"
          >
            <span>editorial here &gt;&gt;&gt;</span>
            <br></br>
          </Link>
        </div>
      </div>
      <div className="home-program1">
        <div className="home-program1-bg">
          <div className="home-program1-bg-l">
            <img
              alt="image"
              src="/oddsandends/oe-08-pillar.png"
              loading="eager"
              className="home-image23"
            />
            <img
              alt="image"
              src="/oddsandends/oe-08-pillar.png"
              loading="eager"
              className="home-image24"
            />
          </div>
          <img
            alt="image"
            src="/oddsandends/oe-09-bar.png"
            loading="eager"
            className="home-image25"
          />
          <div className="home-mask2"></div>
        </div>
        <div className="home-program1-body">
          <span className="home-text122 OE-SubheadingAN">
            <span>program_01</span>
            <br></br>
          </span>
          <span className="program-head-text OE-Headline">
            <span>delete party</span>
            <br></br>
          </span>
          <div className="home-program1-body-r2">
            <span className="home-text128 OE-BodyF25">
              <span>5 &amp; 19 March 2025</span>
              <br></br>
              <span>Wednesdays</span>
              <br></br>
              <span>7 – 9pm</span>
              <br></br>
              <br></br>
              <span>Potato Productions</span>
              <br></br>
              <span>284 River Valley Road</span>
              <br></br>
              <span>Singapore 238325</span>
              <br></br>
              <br></br>
              <span>
                Free
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <br></br>
            </span>
            <span className="home-text145 OE-BodyAN">
              Come over to the Feelers office after hours for a joyful
              confrontation with your digital detritus: we’ll be closing tabs,
              decluttering desktops and clearing out our downloads folders. Stay
              on for snacks with new friends, and to share what you&apos;ve
              saved.
            </span>
          </div>
        </div>
      </div>
      <div className="home-program2">
        <div className="home-program2-bg">
          <img
            alt="image"
            src="/oddsandends/oe-10-circuitboard.png"
            loading="eager"
            className="home-image26"
          />
          <div className="home-mask3"></div>
        </div>
        <div className="home-program2-body">
          <span className="home-text146 OE-SubheadingAN">
            <span>program_02</span>
            <br></br>
          </span>
          <span className="program-head-text OE-Headline">contextual</span>
          <span className="home-text150 OE-BodyAN">
            <span>
              How might we capture, collage, and dis/organise what we glean
              through our online wanderings? 
            </span>
            <br></br>
            <br></br>
            <span>
              Using a purpose-built Chrome extension, featured writers Samuel
              Caleb Wee, Andrew Devadason, and Izyanti Asa’ari excavate the
              poetic potential of an often-overlooked piece of internet
              infrastructure: the browser and its context menus.{" "}
              <b>Contextual Thread</b> reimagines the act of bookmarking,
              inviting you to right-click to create a nested, fragmentary
              archive of text as you surf the web.
            </span>
          </span>
          <div className="home-program2-body-r2">
            <span className="program-head-text OE-Headline">thread</span>
            <div className="home-program2-body-c2">
              <span className="home-text156 OE-BodyAN">
                <b>Contextual Thread</b> is a collaboration between Feelers and
                Bảo Anh Bùi, a graphic designer/website developer based in
                Vietnam and Singapore.
              </span>
              <span className="home-text157 OE-BodyF25">
                <a
                  href="https://contextual-thread.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-link1 OE-BodyF25"
                >
                  view here >>>
                </a>
                <br></br>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="home-program3">
        <div className="home-program3-bg">
          <img
            alt="image"
            src="/oddsandends/oe-11-wires.png"
            loading="eager"
            className="home-image27"
          />
          <img
            alt="image"
            src="/oddsandends/oe-06-blue.png"
            loading="eager"
            className="home-image28"
          />
          <div className="home-mask4"></div>
        </div>
        <div className="home-program3-body">
          <span className="home-text160 OE-SubheadingAN">
            <span>program_03</span>
            <br></br>
          </span>
          <span className="program-head-text OE-Headline">
            <span>open ending</span>
            <br></br>
          </span>
          <div className="home-program3-body-r2">
            <span className="home-text166 OE-BodyF25">
              <span>8 March 2025, </span>
              <span>Saturday</span>
              <br></br>
              <span>2 – 5pm</span>
              <br></br>
              <br></br>
              <span>Potato Productions</span>
              <br></br>
              <span>284 River Valley Road</span>
              <br></br>
              <span>Singapore 238325</span>
              <br></br>
              <br></br>
              <span>$5–15, Sliding Scale</span>
              <br></br>
            </span>
            <span className="home-text182 OE-BodyAN">
              <span>
                If we cannot stop ruin, how can we reimagine what might take
                root amongst the rubble? Can we use the technology of narrative
                to erode, mutate, and recast the narrative of technology? Where
                are we at odds with these stories? What ends are they employed
                for? 
              </span>
              <br className="OE-BodyAN"></br>
              <br className="OE-BodyAN"></br>
              <span>
                <b>Open Ending</b> is an afternoon of speculation. Beginning
                with a Post-Growth Circle, hosted by PostGrowth Singapore,
                participants will read and discuss alternatives to the tech
                industry’s status quo of unfettered growth. This will be
                followed by a speculative writing workshop facilitated by
                multidisciplinary artist ila. 
              </span>
              <br className="OE-BodyAN"></br>
            </span>
          </div>
          <a
            href="https://forms.gle/Q9R3TDn3NVktm5g76"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link1 OE-BodyF25"
          >
            <span>register here &gt;&gt;&gt;</span>
            <br></br>
          </a>
        </div>
      </div>
      <div className="home-program4">
        <div className="home-program4-bg">
          <img
            alt="image"
            src="/oddsandends/oe-12-cnt.png"
            loading="eager"
            className="home-image29"
          />
          <img
            alt="image"
            src="/oddsandends/oe-06-blue.png"
            loading="eager"
            className="home-image30"
          />
          <div className="home-mask5"></div>
        </div>
        <div className="home-program4-body">
          <span className="home-text190 OE-SubheadingAN">
            <span>program_04</span>
            <br></br>
          </span>
          <span className="program-head-text OE-Headline">
            <span>circuits &amp;</span>
            <br></br>
          </span>
          <div className="home-program4-body-r21">
            <span className="home-text196 OE-BodyF25">
              <span>22 March 2025, </span>
              <span>Saturday</span>
              <br></br>
              <span>2 – 5pm</span>
              <br></br>
              <br></br>
              <span>Potato Productions</span>
              <br></br>
              <span>284 River Valley Road</span>
              <br></br>
              <span>Singapore 238325</span>
              <br></br>
              <br></br>
              <span>$5–15, Sliding Scale</span>
              <br></br>
            </span>
            <span className="home-text212 OE-BodyAN">
              <span>
                <b>Circuits and Transits</b> is a participatory ritual which
                takes disassembly as an entry point into techno-spirituality. It
                explores the symbolic and spiritual meanings which emerge from
                the practice of disassembly, and invites participants to
                repurpose parts of discarded devices to create, then activate,
                talismans for wayfinding. 
              </span>
              <br></br>
              <br></br>
              <span>
                <b>Circuits and Transits</b> is a collaboration between Feelers
                and Victoria Hertel. 
              </span>
              <br></br>
            </span>
          </div>
          <a
            href="https://forms.gle/De1musyc1cLUZ9ibA"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link2 OE-BodyF25"
          >
            <span>register here &gt;&gt;&gt;</span>
            <br></br>
          </a>
          <span className="program-head-text OE-Headline">
            <span>transits</span>
            <br></br>
          </span>
        </div>
      </div>

      <div className="home-closing">
        <div className="home-closing-bg">
          <img
            alt="image"
            src="/oddsandends/oe-13-htgo.gif"
            loading="eager"
            className="home-image31"
          />
        </div>
        <div className="home-closing-body">
          <span className="home-text223 OE-SubheadingAN">
            <span>closing panel</span>
            <br></br>
          </span>
          <span className="program-head-text-bigger OE-Headline">
            <span>how to go on</span>
            <br></br>
          </span>
          <div className="home-program4-body-r22">
            <span className="home-text229 OE-BodyF25">
              <span>27 March 2025, </span>
              <span>Thursday</span>
              <br></br>
              <span>7 – 9pm</span>
              <br></br>
              <br></br>
              <span>Potato Productions</span>
              <br></br>
              <span>284 River Valley Road</span>
              <br></br>
              <span>Singapore 238325</span>
              <br></br>
              <br></br>
              <span>$5–15, Sliding Scale</span>
              <br></br>
            </span>
            <span className="home-text245 OE-BodyAN">
              <span>
                What happens after a beginning? After finding our ways to each
                other, how do we continue walking together? Even as we celebrate
                newness, beginnings, and innovation, sometimes the most
                difficult thing to do is simply to go on. 
              </span>
              <br className="OE-BodyAN"></br>
              <br className="OE-BodyAN"></br>
              <span>
                <b>How To Go On</b> is an intimate conversation with young arts
                professionals whose work helps us to build spaces for dialogue
                between different ideas and forms. 
              </span>
              <br className="OE-BodyAN"></br>
              <br className="OE-BodyAN"></br>
              <span>
                Come thread thoughts together like beads upon a necklace. Come
                make sense of how we make sense of things. We’ll share about how
                each of our programming practices continues to emerge, ask about
                how to ask better questions, and think through how to stay open
                and curious. As always, we’ll ask and share responses to
                questions of survival, sustainability, and how to dig our heels
                in once we’ve arrived.
              </span>
              <br className="OE-BodyAN"></br>
            </span>
          </div>
          <a
            href="https://forms.gle/ESKPFdRZg57QYCdm8"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link3 OE-BodyF25"
          >
            <span>register here &gt;&gt;&gt;</span>
            <br></br>
          </a>
        </div>
        <div className="home-mask7"></div>
      </div>
      <div className="home-footer-container">
        <Footer
          link={
            <Fragment>
              <span className="home-text256">
                © 2025 by Feelers. All Rights Reserved. Feelers is a project
                housed within Potato Productions.
              </span>
            </Fragment>
          }
          link1={
            <Fragment>
              <span className="home-text257">
                More information about our Privacy Policy.
              </span>
            </Fragment>
          }
          rootClassName="footerroot-class-name"
        ></Footer>
      </div>
    </div>
  );
};

export default Home;
