import React from "react";

import PropTypes from "prop-types";

import "./profile.css";

const Profile = (props) => {
  return (
    <div className={`profile-profile ${props.rootClassName} `}>
      <img src={props.profileImg} className="profile-image" />
      <span className="profile-title Ttile-S">{props.profileTitle}</span>
      <span className="profile-name Body-S">{props.profileName}</span>
      <span className="profile-bio Body-S">{props.profileBio}</span>
      <span className="profile-interests Body-S">{props.profileInterests}</span>
    </div>
  );
};

Profile.defaultProps = {
  profileTitle: "Title",
  profileImg: "",
  rootClassName: "",
  profileName: "Name",
  profileBio: "Bio",
  profileInterests: "Interests",
};

Profile.propTypes = {
  profileTitle: PropTypes.string,
  profileImg: PropTypes.string,
  rootClassName: PropTypes.string,
  profileName: PropTypes.string,
  profileBio: PropTypes.string,
  profileInterests: PropTypes.string,
};

export default Profile;
