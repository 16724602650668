import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import "./style.css";
import ToDwellALittleLonger from "./views/to-dwell-a-little-longer";
import ReflectiveEssay from "./views/reflective-essay";
import Archive from "./views/archive";
import Contact from "./views/contact";
import PrivacyPolicy from "./views/privacy-policy";
import About from "./views/about";
import Home from "./views/home";
import NotFound from "./views/not-found";
import Projects from "./components/projects";
import Overview from "./components/overview";

// const App = () => {
//   return (
//     //{/* <Router>
//       {/* <Routes
//         component={ToDwellALittleLonger}
//         exact
//         path="/to-dwell-a-little-longer"
//       />
//       // <Route component={ReflectiveEssay} exact path="/reflective-essay" />
//       // <Route component={Archive} exact path="/archive" element={<Projects />} />
//       // <Route component={Contact} exact path="/contact" />
//       // <Route component={PrivacyPolicy} exact path="/privacy-policy" />
//       // <Route component={About} exact path="/about" />
//       // <Route component={Home} exact path="/" />
//       // <Route component={NotFound} path="**" />
//
//       <div className="archive-bg-container">
//         <img src="/feelers/bgcircle.png" className="archive-image1" />
//         <img src="/feelers/bgcircle.png" className="archive-image2" />
//         <img src="/feelers/bgcircle.png" className="archive-image3" />
//       </div>
//       <div className="archive-navbar-container">
//         <Navbar navImgSrc="/feelers/feelerslogo.png"></Navbar>
//       </div> */}
//       //{/* main page starts here */}
//     //   <Routes>
//     //     <Route path="archive" element={<Projects />}>
//     //       <Route path=":projectTitle" element={<Overview />}></Route>
//     //     </Route>
//     //   </Routes>
//     // </Router> */}
//
//     <Router>
//       <Routes>
//         <Route path="/" element={<Home />} />
//       </Routes>
//     </Router>
//
//   );
// };

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contact />} />
        <Route path="reflective-essay" element={<ReflectiveEssay />} />
        <Route
          path="to-dwell-a-little-longer"
          element={<ToDwellALittleLonger />}
        />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />

        <Route path="archive" element={<Projects />}>
          <Route path=":projectTitle" element={<Overview />}></Route>
        </Route>
      </Routes>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById("app"));
//export default App
