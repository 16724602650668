import React from "react";
import { useOutletContext, useParams } from "react-router-dom"; // allows passing data in from parent, essential for this component

import PropTypes from "prop-types";

import "./overview.css";

const Overview = (props) => {
  const context = useOutletContext();
  var params = useParams();
  return (
    <div className="overview-container">
      <h1 className="overview-title Body-L">{context.title}</h1>
      <span className="overview-text Body-M">{context.overview}</span>
      <div className="overview-gallery">
        <img
          classname="overview-galley"
          _jsm
          src={context.image1}
          className="overview-image1"
        />
        <img src={context.image2} className="overview-image2" />
      </div>
    </div>
  );
};

Overview.defaultProps = {
  rootClassName: "",
  title: "Title",
  text: "Synopsis",
  imageId: "",
  imageId1: "",
};

Overview.propTypes = {
  rootClassName: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  imageId: PropTypes.string,
  imageId1: PropTypes.string,
};

export default Overview;
