import React, { Fragment } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
  Link,
  useParams,
  Outlet,
} from "react-router-dom";
import PropTypes from "prop-types";
import Footer from "./footer";
import Overview from "./overview";
import Navbar from "../components/navbar";
import "./projects.css";

const Projects = (props) => {
  var params = useParams();
  if (!("projectTitle" in params)) {
    //default to one of the titles when no entry has been clicked yet
    params = { projectTitle: "rain-check" };
  }
  console.log(params);
  return (
    <div className="main-container">
      <div className="archive-navbar-container">
        <Navbar navImgSrc="/feelers/feelerslogo.png"></Navbar>
      </div>
      <div className="projects-container">
        <div className="projects-table-container">
          <table className="projects-table">
            <thead>
              <tr>
                <th className="projects-header date">Date </th>
                <th className="projects-header title">Project Title </th>
                <th className="projects-header category">Category </th>
                <th className="projects-header status">Status </th>
              </tr>
            </thead>
            <tbody>
              {
                //the line below iterates through the 'projectdata' array and creates a table line from each entry in projectdata
                Object.entries(projectdata2).map(([id, entry]) => (
                  <tr key={id}>
                    {/*key is necessary for some reason otherwise React throws a fit*/}
                    <td className="projects-cell-contents">
                      {entry.date ?? "Date"}
                    </td>
                    <td>
                      <NavLink
                        to={`/archive/${id}`}
                        className="projects-cell-contents"
                      >
                        {entry.title}
                      </NavLink>
                    </td>
                    <td className="projects-cell-contents">
                      {entry.category ?? "Category"}
                    </td>
                    <td className="projects-cell-contents">
                      {entry.status ?? "Status"}
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>

        {/* ------------ OVERVIEW STARTS HERE --------------- 
			<Outlet> object links to overview.js and is passed the entry n projectdata2 for the current projectTitle
			 */}
        <div className="projects-overview">
          <Outlet context={projectdata2[params.projectTitle]} />
          {Object.entries(projectdata2).map(
            ([id, entry]) => (
              <div className="overview-container-mobile">
                <h1 className="overview-title Body-L">{entry.title}</h1>
                <span className="overview-text Body-M">{entry.overview}</span>
                <div className="overview-gallery">
                  <img src={entry.image1} className="overview-image1" />
                  <img src={entry.image2} className="overview-image2" />
                </div>
              </div>
            )

            //<tr key={id}>{[>key is necessary for some reason otherwise React throws a fit<]}
            //<td className="projects-cell-contents">{entry.date ?? "Date"}</td>
            //<td>
            //<NavLink to={`/archive/${id}`} className='projects-cell-contents'>{entry.title}</NavLink>
            //</td>
            //<td className="projects-cell-contents">{entry.category ?? "Category"}</td>
            //<td className="projects-cell-contents">{entry.status ?? "Status"}</td>
            //</tr>
          )}
        </div>
      </div>
    </div>
  );
};

const projectdata2 = {
  "rain-check": {
    // <---- this key will be the url. so 'rain-check' will give a url of /archive/rain-check etc
    date: "Jan 2025",
    title: "Rain Check",
    category: "Exhibition/Performance",
    status: "Ongoing",
    image1: "",
    overview:
      "The sky has fallen, the Cloud has collapsed, and life goes on. Step into a world where sunlight determines our ability to go online and the monsoon season precipitates a time of digital silence. In this performance installation, we invite you to enter a speculative future where pirates siphon and distribute content from disguised satellites, and search engines are treasured relics of a time gone by. Through kinetic sculpture and sensor choreography, Rain Check explores the role of internet infrastructures in our everyday lives by imagining their intermittent absence. This work-in-development is a continuation of perfor.ml, a project investigating the possibility of bringing machine learning into performance—on whose terms, and for what goals?",
  },
  "kl-art-book-fair": {
    date: "Dec 2024",
    title: "KL Art Book Fair",
    category: "Event",
    status: "Completed",
    overview: "",
  },
  "bug-month-caterfeelers": {
    date: "Nov 2024",
    title: "Bug Month: Caterfeelers: Build A Bug",
    category: "Workshop",
    status: "Completed",
    overview:
      "Come over for a cosy workshop, where we’ll build and bring home electronic familiars of our own. Like an ant fallen from a tree onto the moving plateau of your shoulders, these bodily companions stick close to you, open to touch and shifts. Perhaps it will be a good distraction. Perhaps it will be a friend for you wherever you go.",
  },
  "bug-month-debug-ur-darlings": {
    date: "Nov 2024",
    title: "Bug Month: Debug ur Darlings",
    category: "Workshop",
    status: "Completed",
    overview:
      "Love is a ritual of repair, and repair, a ritual of love. At this repair-a-thon, we gather for a practice of self-sufficiency, care, and maintenance that resists a culture of disposability and embraces breakdowns as opportunities to learn. Bring something you want to fix instead of throw. We’ll guide you in diagnosing what might be wrong with the item and trying to fix it together.",
  },
  "bug-month-byobs": {
    date: "Nov 2024",
    title: "Bug Month: Bring Ur Own Bug Spray",
    category: "Workshop",
    status: "Completed",
    overview: `	Like a phantom in a machine, a moth caught in a relay, or a cockroach whispering from a telephone, a bug can be a dreadful haunting or a pleasant surprise. Through a series of speculative writing and coding exercises, participants will contribute to a compendium of bug lore that documents our anxieties around the fear of tech and tech failure through the courage of bug spray. 

	Bring Your Own Bug Spray is a response to the exhibition Sorry For The Technical Difficulties, which runs from 8-17 Nov at Art Outreach. `,
  },
  "sgabf-2024": {
    date: "Nov 2024",
    title: "Singapore Art Book Fair 2024",
    category: "Event",
    status: "Completed",
    overview: "",
  },

  "ttrpg-with-feelers": {
    date: "Oct 2024",
    title: "TTRPGS with Feelers",
    category: "Event",
    status: "Completed",
    overview:
      "We’ll be playing: Apollo 47 Technical Handbook Engage in workaday astronaut activities using a simple improvisatory rules set to play in a quiet world of technobabble and the slow progression of science, as well as Move Quietly and Tend Things, A bittersweet utopia story-game about recovery, repair and revelation, set in a derelict Southeast Asian megastructure sometime in the far future.",
  },
  "feeler-turns-4": {
    date: "Oct 2024",
    title: "Feelers Turns 4",
    category: "Event",
    status: "Completed",
    overview: "",
  },
  "cloud-cryptid": {
    date: "Sep 2024",
    title: "Cloud Cryptid Friendship Society",
    category: "Performance",
    status: "Completed",
    overview: `	Join a small but growing group of devoted friends, working tirelessly to protect and befriend our local Cloud Cryptid population.

	Our skies, seas and streets are full of these mysterious but loveable creatures. As consumers of the Internet’s noise and detritus, Cloud Cryptids are a keystone species in our Internet ecosystem, with populations clustering around physical communications infrastructure.  

	The Singapore Chapter of the Society needs your help to connect with and catalogue our local Cloud Cryptid population, and ensure their continued survival.`,
  },
  "silent-reading": {
    date: "Jun 2024",
    title: "Silent Reading",
    category: "Research / Development",
    status: "Ongoing",
    overview: `Drop in to read, ramble and daydream with us! We’ll be at @strangerconvos’ space at #02-38D Pearl’s Hill Terrace from 12-6pm.

Browse our team’s favourite art & tech publications, from @sfpc_nyc’s Dictionary of Dark Matters, to Liturgy of the Archipelago, our profile of @develo.ph founder Chia Amisola. 
`,
  },
  "szn-2024-touch-base": {
    date: "May 2024",
    title: "Season 2024: Touch Base (how to go on)",
    category: "Season",
    status: "Completed",
    overview: (
      <p>
        <b>HOW TO GO ON</b>: Join us for an intimate conversation with our
        friends School of Altruism @schoolofaltruism and The Slow Press
        @theslowpress on how we endure as independent collectives. <br />
        <br />
        <b>RANDOM ACCESS MEMORY</b>: Expand the limits of what an embodied
        encounter with technology could look and feel like in this movement
        workshop by Chew Shaw En @chewshawen <br />
        <br />
        <b>TOUCH_TIME(T_T)</b>: Drop in to get in touch with us, make friends,
        and play Cards Against Technology, a game that we need your help to
        finish making <br />
        <br />
        <b>LET ME SHOW YOU WHAT I SAW</b>: Explore technologies of
        representation in art & culture, with this this workshop held together
        with Open Offering by @abouttoask <br />
        <br />
        <b>LITURGY OF THE ARCHIPELAGO</b>: Filipino internet artist Chia Amisola
        @hotemogf, who founded @develo.ph, a nonprofit community that seeks to
        promote the use of ‘technology as a tool for liberation rather than
        oppression.’ Chia reflects on archipelagic memory & territory, the
        visibility of technology & labor, organising and ritual, devotion as
        technology, and folk histories. They also share snippets of projects
        past and present, including initiatives with the Philippine Internet
        Archive and KAKAKOMPYUTER MO YAN!
      </p>
    ),
  },
  performl: {
    date: "Mar 2024",
    title: "perfor.ml",
    category: "Performance",
    status: "Completed",
    overview: `Through perfor.ml, we investigate the possibility of bringing machine learning into performance—on whose terms, and for what goals?

Our latest iteration in this research process is a performance installation, bringing together human performers and kinetic sculptures on stage to explore systems of maintenance and upkeep in a subsea data centre
`,
  },
  pressplay: {
    date: "Jan 2024",
    title: "pressplay 2024: How To Build A Data Centre (Excerpt)",
    category: "Performance",
    status: "Completed",
    overview: `In How To Build A Data Centre (Excerpt), bits of data, compressed and uniformly stored, are represented by two-dimensional image stacks of photographic print. Each successive frame of the kinetic installation carries the suggestion of a stop-motion film, yet no narrative is progressed. The flattening of information and the repetitive excess of time creates an infinite present that is prolonged into an indefinite future in conflict with everything living, changing, and developing in the world outside. Within this imagined data centre, scarcity becomes abundance.
`,
  },
  sonicality: {
    date: "Dec 2023",
    title: "Sonicality",
    category: "Research / Development",
    status: "Completed",
    overview: `What’s your sound personality?

From the unique timbre and texture of your voice to your own taste for music and sound, there is an audio side of what makes you, you.

Discover your sonic personality through our quiz that will make you appreciate the countless ways sounds shape your life.
`,
  },
  "soai-lite-2023": {
    date: "Oct 2023",
    title: "SoAI Lite",
    category: "Education",
    status: "Completed",
    image1: "/assets/soailite/sl-2.webp",
    overview: `The School of Alternate Internets is an alt-educational program by Feelers for critical thinking, doing, and undoing around technology. After an in-person run of the school in July, we’re running a fully online, modular version of the program. Join us for SoAI Lite, from wherever you are in the world, for whichever sessions you want to attend.

With 11 drop-in sessions across 3 days (2, 3 and 7 December), we’ll be offering:

Primers to give you a comprehensive introduction on three contested emerging technologies: AI, the Metaverse, and Blockchain,
Lectures that bring quirky & non-obvious perspectives to our technological experience, &
Workshops that allow you to put these ideas into action, and expand your ways of doing, feeling, and thinking about technology beyond its usual language and logic.
`,
  },
  totapo: {
    date: "Sep 2023",
    title: "Totapo",
    category: "Education",
    status: "Completed",
    image1: "/assets/work/totapo.webp",
    image2: "/assets/totapo/t-2.webp",
    overview: (
      <p>
        <b>A DATE WITH AN ETHER FRIEND:</b>
        Folder poetry is a genre of artworks that explore creative,
        machine-friendly ways of browsing files and understanding data. The
        Residents sought to introduce vivid and exciting ways for visitors to
        navigate through the network, and look into the eyes of the Ether
        friend.
        <br />
        <br />
        <b>WHERE IS DECENTRALIZATION?</b>: Decentralisation for what?
        Decentralisation for who? Despite the technical fact that every person
        or group can set up a node and sync with the network, socio-economic
        structures and global resource allocations mean that an overwhelming
        amount of Ethereum nodes exist in the Global North.
      </p>
    ),
  },
  "feeler-turns-3": {
    date: "Sep 2023",
    title: "Feelers Turns 3",
    category: "Event",
    status: "Completed",
    overview: `You’re invited to Feelers’ 3rd birthday! 
Come and try out our kinetic cocktails, get your astro chart read & dance to a DJ set by ila!`,
  },
  "soai-2023": {
    date: "Jul 2023",
    title: "School of Alternate Internets",
    category: "Education",
    status: "Completed",
    image1: "/assets/soailite/sl-1.webp",
    image2: "/assets/soailite/sl-17.webp",
    overview: `The School of Alternate Internets begins tomorrow – this new educational program will bring critical perspectives to 3 contested emerging technologies: blockchain, A.I., and Web3. 

WEB WEAVING: Participants will also explore how we might meet our local computing needs with permacomputing, and build a personal low-power web server. 
GAME OF LIFE: Join Feelers as we explore the performance possibilities of Conway’s Game of Life, a mathematical model that abstractly represents life, death, and birth.  The model’s output is both simple and unpredictably complex. Chaotic patterns arise from a tidy set of rules. What does it mean to perform with an intelligence that is other-than-human? How can our bodies play what is famously described as a ‘zero-player game’?  Feelers will workshop these ideas with four performances as part of eXchAnge 2023, a programme series by @esplanadesingapore that explores colaborations across cultures, genres and fields.
LOW TECH WEB: Participants will also explore how we might meet our local computing needs with permacomputing, and build a personal low-power web server. 
GAMING THE METAVERSE: Long before the notion of the Metaverse was popularised by corporations to describe profit-driven digital worlds, video games and gameful worlds served as Metaverses for digital kinship and community. Through play and collaboration, SoAI participants will explore emergent online communities and game the logic of the Metaverse.Participants will also investigate ritual as a site where technology and divinity converge, and consider what it would mean to form symbiotic and sacred relationships with their devices.
MYTH/PROPHECY/MUTATION: Untangle the myths surrounding Web3 and explore how we can use mutation as a technique and strategy for disrupting existing narratives and creating new possibilities for the future. Re-think linear histories of the Web as we delve into Hypertext, a form of branching, non-sequential writing that is foundational to the Internet. Revisit earlier forms of hypertext systems and create a collaborative hypertext fiction.
`,
  },
  sketch2pigeon: {
    date: "May 2023",
    title: "sketch2pigeon",
    category: "Workshop",
    status: "Completed",
    image1: "/assets/sketchpigeon/sp-1.webp",
    overview: `sketch2pigeon is a beginner’s machine learning workshop which traces the history of image-based ML techniques and their interfaces. Participants will learn how to access and make use of existing pre-trained models to create their own unique projects with minimal coding experience.

This 2.5-hr workshop will touch on tools such as GANs, Midjourney, DALL-E, and ChatGPT. No experience with machine learning and computing is necessary.
`,
  },
  "how-to-start": {
    date: "Apr 2023",
    title: "How to Start, How to Go On",
    category: "Print",
    status: "Completed",
    overview: `How To Start, How To Go On is a collection of conversations, project lists, manifestos and essays that trace Feelers’ journey since its beginning in 2020.

These speculative and silly works give a snapshot of Feelers’ vision as an art-tech label that is working towards a more sustainable, inhabitable and lively world. 
`,
  },
  "islands-residency": {
    date: "Mar 2023",
    title: "Islands Residency",
    category: "Event",
    status: "Completed",
    overview: `ASTRO READINGS:  Uncover new insights into your gifts, challenges, possibilities and opportunities. We welcome any questions you may have, but do note that our readings are not meant to be prescriptive or instructional. TLDR: @natto.sim closed out our 3-month residency at I_S_L_A_N_D_S this Sunday with TLDR (Touch + Light, Determine Reaction), a workshop on how to build an interactive light circuit activated by touch! 
BUILD OUR OWN SERVER (BOOS):  BOOS is a workshop where we build a low power web server together to give islands their very own local network. 
CRFRN: Cybernetic Rituals For Right Now (CRFRN) is a workshop where we design cybernetic rituals for ourselves - for fun, joy, healing, and exploration. These rituals will incorporate simple feedback loops, and look at how our devices can also be spiritual & emotional companions.
`,
  },
  sgabf2023: {
    date: "Apr 2023",
    title: "Singapore Art Book Fair 2023",
    category: "Event",
    status: "Completed",
    overview: "",
  },
  "pecha-kucha": {
    date: "Mar 2023",
    title: "Pecha Kucha",
    category: "Event",
    status: "Completed",
    overview: `PechaKucha, in Japanese, actually means “chit-chat”. Using the PechaKucha 20 slides x 20 seconds presentation format, we heard five speakers from diverse industries share a range of topics related to art, tech or both.

PechaKucha #2 (Jun 2023) : Presentations covered the use of eye tracking in user experience design by James Breeze, comparison between art and tech approaches by Julian Wong @wjwjulian and speculative design and AR by Renee Wong @ruhnehwong

We then heard from @simon_chesterman of AI Singapore on whether we should trust AI, @kontinentalist's Siti Aishah on data storytelling and maps, as well as the intersection of Malay drums and electronic music by @Syafiqhalid

Thank you to all our guests and speakers for making the event a success, and we hope you’ve managed to make new friends, network and gleam exciting insights from the world of art and tech.
`,
  },
  "writing-machine": {
    date: "Mar 2023",
    title: "Writing Machine",
    category: "Print",
    status: "Completed",
    image1: "/assets/writemachine/wm-1.webp",
    image2: "assets/writemachine/wm-1.webp",
    overview: `
Through exercises that span the literary, computational and divinatory, Feelers’ Company Lead Ang Kia Yee @kyatos engages with authorship as inherently shared — with code, chance, constraints, divination, their subconscious, and other human beings.
`,
  },
  "generative-dreaming": {
    date: "Nov 2022",
    title: "Generative Dreaming",
    category: "Exhibition / Performance",
    status: "Completed",
    image1: "/assets/gendream/gd-1.webp",
    image2: "/assets/gendram/gd-2.webp",
    overview: `Generative Dreaming is a series of conversations around art-making.

In each episode, two artists chat about what, why, and how they make they make, as well their visions for the future and the wider world. Their conversations reveal how we persist through care, community and creative strategy — not just as artists, but also as people.
`,
  },
  "good-am-to-you": {
    date: "Nov 2022",
    title: "Good AM To You",
    category: "Education",
    status: "Completed",
    image1: "/assets/goodam/ga-1.webp",
    image2: "/assets/goodam/ga-2.webp",
    overview: `Feelers is back at the library for one last 2022 rodeo, focusing on Good Morning graphics typically sent over WhatsApp as greetings, reminders and well wishes.

We've made a lil generator for you to make your own Good AM graphics at a booth (1-4pm), and will be hosting a sharing + live designing showdown (2-3pm) between three graphic designers — Qing (OuterEdit), Iffah (Fellow), and Izyanti (Fellow).

Come play, make images, and rate the designers' designs. May the best Good AM win! 
`,
  },
  "through-the-neighbours-walls": {
    date: "Nov 2022",
    title: `Through The Neighbour's Walls`,
    category: "Exhibition / Performance",
    status: "Completed",
    image1: "/assets/neighbourswalls/nw-1.webp",
    image2: "/assets/neighbourswalls/nw-2.webp",
    overview: `The interactive rotary phone prop was created for Through the Neighbour's Walls, an experiential public installation which is an extension of the first episode of The Everyday Museum’s self-guided audio trail series, Speaking of which – An Organic Terminus.
`,
  },
  "another-world-is-possible": {
    date: "Sep 2022",
    title: "Another World Is Possible",
    category: "Research / Development",
    status: "Completed",
    image1: "/assets/anotherworld/aw-4.webp",
    image2: "/assets/anotherworld/aw-1.webp",
    overview: `Our second NLB programme is Another World is Possible, a panel discussion about the role of design and technology in building a more ethical and equitable world.

Bringing together Saad Chinoy (SpudnikLab), Claudia Poh (Werable) and Wendy Wong (55 Minutes), Another World is Possible responds to the pressing need for designs and technologies which value and empower the autonomy and needs of their users.

In the process, the panel will touch upon the deepening digital divide between the super-rich and underprivileged, the ongoing shift from Web 2.0 to Web 3.0, as well as various considerations in achieving empathetic and user-centred design.
`,
  },
  "greener-screens": {
    date: "Sep 2022",
    title: "Greener Screens",
    category: "Education",
    status: "Completed",
    image1: "/assets/greenerscreens/gs-1.webp",
    image2: "/assets/greenerscreens/gs-4.webp",
    overview: `Greener Screens is a workshop by our Tech Lead Ashley where you'll learn about actionable greener tech habits and build an eco-tech toolkit for your business or personal practice. It draws upon Ashley's research and our discussions at Feelers, and is open to all.
`,
  },
  "talk-touch": {
    date: "Aug 2022",
    title: "Talk Touch",
    category: "Research / Development",
    status: "Completed",
    image1: "/assets/talktouch/tt-1.webp",
    image2: "/assets/talktouch/tt-4.webp",
    overview: `We believe in producing works that encourage curiosity, collaboration, and experimentation. So we're excited to share that earlier this year, we were matched with a group of 5 @sutdsg students as industry partners for their Capstone project. We mentored the Capstone team over a 7-months long process of ideation, development and prototyping, where the students worked on our proposed idea of a haptic wearable.

This culminated in their capstone showcase, where they presented a prototype called TalkTouch. As the first-ever haptic device of its kind, TalkTouch has the potential to redefine remote communication and intimacy, with possible applications in the healthcare and arts industries. 
`,
  },
  caterfeelers: {
    date: "Jun 2022",
    title: "Caterfeelers",
    category: "Workshop",
    status: "Completed",
    image1: "/assets/caterfeelers/cat-1.webp",
    overview: `4 o'clock comprises four kinetic jewelry designs that meld electronics and jewelry to form your new city companions. 
`,
  },
  "kinetic-birds": {
    date: "May 2022",
    title: "Kinetic Birds",
    category: "Workshop",
    status: "Completed",
    image1: "/assets/kineticbirds/kb-2.webp",
    image2: "/assets/kineticbirds/kb-1.webp",
    overview: `We're finally running another round of our Kinetic Birds workshop, where you'll build your very own kinetic birds project using Arduino and servo motors. Designed for beginners by our Tech Lead @ashleyzhiqi, this hands-on workshop offers a mix of guidance and play.
Workshop was repeated again in Aug 2023. 
`,
  },
  "office-hands-edition": {
    date: "May 2022",
    title: "Office: Hands Edition",
    category: "Research / Development",
    status: "Completed",
    overview: `Xafiér had a desk job and was suffering from finger cramps, neck strains… commonly faced by many office workers. This experience founded the inspiration for the artwork— an experiment to understand the performance of the human body around objects of technology. Namely, the somatic relationship between the office employee and the laptop.

Xafiér self-monitored their movements in the office. They found out that the sedentary body was stiff, tense and withheld. These movements were replicated in a motion capture studio.

However— with any sort of technology— Xafiér’s body had to shift and exaggerate their movements in order to reproduce the same postures. The video is a result of this experiment. 'Office: Hands Edition' depicts the human body conforming to objects of technology — one, with the laptop; and two, with the motion capture technology.

`,
  },
  "streetlamp-107101": {
    date: "Mar 2022",
    title: "Tracing Latencies:  Streetlamp No. 107101",
    category: "Exhibition / Performance",
    status: "Completed",
    image1: "/assets/streetlamp/street-1.webp",
    image2: "/assets/streetlamp/street-2.webp",
    overview: `What is the darkest route we can take from one point to another?
Singapore has at least 107,100 street lamps on our streets, back alleys and roads. Taking their artwork 'Streetlamp No. 107101' as a starting point, art & technology collective Feelers will guide you through a walking and mapping process to seek out lesser lit trails.
By searching for darkness, we drift off habitual pathways to explore, observe, respond and re-orientate within our brightly-lit city.
`,
  },
  "youtube-channel": {
    date: "Nov 2021",
    title: "Feelers Youtube Channel",
    category: "Research / Development",
    status: "Completed",
    overview: `Channel Description:  Feelers is an art and technology studio based in Singapore. 
`,
  },
  "build-a-caifan": {
    date: "Nov 2021",
    title: "Build-A-Caifan",
    category: "Exhibition / Performance",
    status: "Completed",
    image1: "/assets/caifan/cf-1.webp",
    overview: `Build-A-Caifan is an imaginary online caifan stall which digitally replicates the experience of picking out dishes at a stall through clicking on food emojis. The dishes selected are reflected real-time as illustrations on a plate before being digitally "cooked" using machine learning to present a final, realistic-looking plate of piping-hot, ready-to-eat caifan.
`,
  },
  "painter-reviews-watercolour": {
    date: "Oct 2021",
    title: "Painter Reviews Watercolour Paints ",
    category: "Exhibition / Performance",
    status: "Completed",
    overview: `We sat down with Mengju (@mengjuice) to learn about watercolour paints! Full video launches tomorrow at 8pm on YouTube and IG TV
`,
  },
  "labs-voices-in-a-room": {
    date: "Aug 2021",
    title: "Labs: Voices In a Room",
    category: "Exhibition / Performance",
    status: "Completed",
    image1: "/assets/labs/viar/viar.webp",
    image2: "/assets/labs/viar/viar-1.webp",
    overview: `Voices in a Room is a Feelers Labs performance project by ants chua, Ang Kia Yee, Chew Shaw En, Helen Cai, Rice Tan and txting teo. While the project initially started as an examination of language's possibilities in responding to and inhabiting times of crisis, current pressing griefs led them to dream collectively about the futures they wish to bring into being.
RELEASED ON SPOTIFY JUN 2024: 
STILL DANCE:Put on your favourite song and wait for the ghosts to arrive. Ask one of them for a dance.
CONFETTI VISITS:Slice your sorrows into bits of confetti. Toss them like seeds onto freshly tilled soil. An invitation: listen and rehearse with us. 
WE TEXT: We come back to these scores for clues. We speak them aloud.
We return to beginnings, fumbling toward an answer.
An invitation: listen and rehearse with us. 
`,
  },
  "labs-across-a-small-distance": {
    date: "Sep 2021",
    title: "Labs: Across a Small Distance",
    category: "Exhibition / Performance",
    status: "Completed",
    image1: "/assets/labs/distance/distance-3.webp",
    image2: "/assets/labs/distance/distance-4.webp",
    overview: `Across a Small Distance is a work-in-progress by Chew Shaw En & Jevon Chandra about the nature of landing, receiving and transmission. Through a sequence of movements and activities, the work explores the textures of communication in small settings: the things too near to touch, misunderstandings too slight to notice, tiny truths too inconvenient to say.
`,
  },
  "100-advertisements": {
    date: "Jul 2021",
    title: "Labs: 100 Advertisements - Now Online!",
    category: "Research / Development",
    status: "Completed",
    image1: "/assets/labs/ads/ads-1.webp",
    image2: "/assets/labs/ads/ads-5.webp",
    overview: `Hey you! Yes, you! Have you ever seen a show that is full of funny advertisements, online and live at the same time, with real-time improvisatory surprises, wild verbal chaos, and cosy dance videos? Come and see 100 Advertisements - Now Online!  World-class entertainer and dancer Kai will take you through an unforgettable journey on Zoom, Google Doc, YouTube and inside your pandemic-hardened, tight-knotted but-still-hopeful soul! DO NOT MISS! Life-changing! Entertaining! A little bit sad! Very interesting!
`,
  },
};

export default Projects;
